import React from "react";
import classes from "../Input/Input.module.scss";


class CustomInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: this.props.value
        }

    }



    setInputHandler = event =>{
        this.setState({ text: event.target.value });
        this.props.onChange(event.target.value);
    }


    render() {
        let showvalue="";
        if (typeof this.state.text !== 'undefined' && this.state.text !== null){
            showvalue=this.state.text.replaceAll('"', '');
        }
        return (
            <>
                <div>
                    <div className={classes['input-bottom-spacer-25']}>
                        <div className='form-group'>
                            <label className={classes.label} >{this.props.label}</label>
                            <input onChange={ this.setInputHandler }
                                   className={`${classes.input} form-control`}
                                   type={this.props.type}
                                   placeholder={this.props.placeholder}
                                   value={showvalue}
                                   name={this.props.name}
                                   required={ this.props.required ? true : false }
                            />
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default CustomInput;