import React from 'react';
import Select from '../UI/Select/Select';
import axios from 'axios/index';

class GenikiStores extends React.Component {

    constructor(props) {
        super(props);
        this.state = { allStores: [] };
    }

    componentDidMount() {
        this.getAllStores();
    }

    getAllStores() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(''+ API_URL +'/api/stores?'+ API_KEY +'&filter[active]=[1]&filter[id_country]=9&sort=[city_ASC]&display=full&output_format=JSON').then(response => {
            if (typeof response.data.stores === 'undefined') {
                this.setState({ allStores: [] });
            } else {
                this.setState({
                    allStores: response.data.stores,
                });
            }
        });
    }

    render() {
        return (
            <>
                <Select label='Κατάστηματα Γενικής' name={`ByerFormShipping[collection-stores]`} data={this.state.allStores}  ismore='1' required={false} />
            </>
        );
    }
}
export default GenikiStores;
