import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const MenuCategoriesData = createContext(null);

export const MenuCategories = props => {
    const [menuCategories, setMenuCategories] = useState([]);

    const API_KEY = `${process.env.REACT_APP_KEY}`;
    const API_URL = `${process.env.REACT_APP_URL}`;
    const MENU_CATEGORIES = `${API_URL}/api/content_management_system?${API_KEY}&filter[active]=[1]&display=full&output_format=JSON`;
    
    useEffect(() => {
        axios
            .get(`${MENU_CATEGORIES}`)
            .then(categories => {               

                setMenuCategories(categories.data.content_management_system);
                 
            })
            .catch(err => {
                console.log(err);
            });          
            
            
    }, [MENU_CATEGORIES]);
    
    
    

    return (
        <MenuCategoriesData.Provider value={{ menuCategories }}>
            {props.children}
        </MenuCategoriesData.Provider>
    );
};
