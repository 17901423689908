import React from 'react';
import ByerForm from './ByerForm';
import Container from '../UI/Container/Container';
import Titles from '../UI/Titles/Titles';
import ContainerDivider from '../UI/ContainerDivider/ContainerDivider';
import { ReactComponent as AddFormIcon } from '../assets/images/svg/add-form-icon.svg';

class Step1 extends React.Component {

    constructor(props) {

        super(props);
        this.removeRow = this.removeRow.bind(this);
        this.onAddOrderChild = this.onAddOrderChild.bind(this);

        if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
            let myarr = allReadyKeys.split('-');
            let pa = [];
            for (let i = 0, l = myarr.length; i < l; i++) {
                pa.push(<ByerForm number={myarr[i]} handler={this.removeRow} flag={i} key={i} />);
            }
            this.state = { formKeys: pa };
        } else {
            this.state = { formKeys: [<ByerForm number='0' handler={this.removeRow} flag={0} key={0} />] };
            localStorage.setItem('ByerFormCount', 'null');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    onAddOrderChild(e) {
        e.preventDefault();
        this.setState({ formKeys: [...this.state.formKeys, <ByerForm number={this.state.formKeys.length} handler={this.removeRow} flag={this.state.formKeys.length} />] });
        let allReadyKeys = localStorage.getItem('ByerFormCount') + '-' + this.state.formKeys.length;
        localStorage.setItem('ByerFormCount', allReadyKeys);
    }

    removeRow(val) {
        let els = document.getElementsByClassName(val + 'mid');
        Array.prototype.forEach.call(els, function (el) {
            el.remove();
            localStorage.removeItem('form['+val+'][product-name]');
            localStorage.removeItem('form['+val+'][product-comment]');
            localStorage.removeItem('form['+val+'][product-link]');

            localStorage.removeItem('form['+val+'][product-price]');
            localStorage.removeItem('form['+val+'][product-quantity]');
            localStorage.removeItem('form['+val+'][product-shop]');

            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('-' + val, '');
            localStorage.setItem('ByerFormCount', allReadyKeys);
        });

        let elsT = document.getElementsByClassName('DtaTitle');
        let p = 0;
        Array.prototype.forEach.call(elsT, function (elp) {
            p++;
            elp.innerHTML = p + 'ο';
        });
    }

    render() {

        return (
            <>
                <Container className='px-2 px-xl-17'>
                    <ContainerDivider className='pt-4 pb-3'>
                        <Titles>
                            <h2>Διαδικασία Καταχώρισης Εντολής Αγοράς Προϊόντων</h2>
                            <p>Η Γενική Ταχυδρομική αγοράζει τα προϊόντα που επιθυμείς για λογαριασμό σου και παραλαμβάνεις σε μια αποστολή.</p>
                        </Titles>
                    </ContainerDivider>

                    {this.state.formKeys}

                    <ContainerDivider className='py-2'>
                        <div className='add-product-button text-center'>
                            <button onClick={this.onAddOrderChild}>
                                Προσθήκη εντολής <AddFormIcon />
                            </button>
                        </div>
                    </ContainerDivider>
                </Container>
            </>
        );
    }
}

export default Step1;
