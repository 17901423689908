import React, {useState, Fragment, useEffect  } from 'react';
import Card from '../../UI/Card/Card';
import RadioCollapse from '../../UI/RadioCollapse/RadioCollapse';
import Input from '../../UI/Input/Input';
import TextArea from '../../UI/TextArea/TextArea';
import GenikiStores from '../../components/GenikiStores';
import axios from "axios/index";
import Select from '../../UI/Select/Select';

function Step2Form() {

    const [isinvoice, setIsInvoice] = useState(false);
    const [isresnew, setIsResNews] = useState(false);

    //const [invoiceYes, setIsInvoiceYes] = useState(false);
    //const [receiptYes, setIsReceiptYes] = useState(true);
    //const [receiptNewYes, setReceiptNewYes] = useState(false);

    const [stateList, setStateList] = useState([]);
    const [CollapseA, setCollapseA] = useState(true);
    const [CollapseB, setCollapseB] = useState(false);

    const API_KEY = `${process.env.REACT_APP_KEY}`;
    const API_URL = `${process.env.REACT_APP_URL}`;
    const MENU_CATEGORIES = `${API_URL}/api/states?${API_KEY}&filter[id_country]=9&filter[active]=[1]%&sort=[name_ASC]&display=full&output_format=JSON`;

    useEffect(() => {
        axios
            .get(`${MENU_CATEGORIES}`)
            .then(response => {
                setStateList(response.data.states);
            })
            .catch(err => {
                console.log(err);
            });


        if (typeof localStorage.getItem('ByerFormShipping[collection-stores]]') !== 'undefined' && localStorage.getItem('ByerFormShipping[collection-stores]') !== null) {
            setCollapseB(true);
            setCollapseA(false);
        }else{
            setCollapseA(true);
            setCollapseB(false);
        }

        if (typeof localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== 'undefined' && localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== null) {
            setIsResNews(true);
            //setReceiptNewYes(true);
            setIsInvoice(false);
            //setIsInvoiceYes(false);
            //setIsReceiptYes(false);
        }else if (typeof localStorage.getItem('ByerFormShipping[businessName]') !== 'undefined' && localStorage.getItem('ByerFormShipping[businessName]') !== null) {
            setIsInvoice(true);
            //setIsInvoiceYes(true);
            setIsResNews(false);
            //setReceiptNewYes(false);
            //setIsReceiptYes(false);
            let elem=document.getElementById('isinvoice1');
            elem.checked=true;
        }else{
            let elem=document.getElementById('isinvoice0');
            elem.checked=true;
        }

    }, [MENU_CATEGORIES]);


    const toogleInvoice = (num) => {
        if(num === 0) {
            //setIsReceiptYes(true);
            setIsInvoice(false);
            //setIsInvoiceYes(false);
            setIsResNews(false);
            //setReceiptNewYes(false);

            localStorage.removeItem('ByerFormShipping[businessFirstName]');
            localStorage.removeItem('ByerFormShipping[businessLastName]');
            localStorage.removeItem('ByerFormShipping[businessName]');
            localStorage.removeItem('ByerFormShipping[businessProfession]');
            localStorage.removeItem('ByerFormShipping[businessAfm]');
            localStorage.removeItem('ByerFormShipping[businessDoy]');
            localStorage.removeItem('ByerFormShipping[businessAddress]');
            localStorage.removeItem('ByerFormShipping[businessTown]');
            localStorage.removeItem('ByerFormShipping[businessZip]');
            localStorage.removeItem('ByerFormShipping[businessTelephone]');
            localStorage.removeItem('ByerFormShipping[businessEmail]');

            localStorage.removeItem('ByerFormShipping[receiptFirst-name]');
            localStorage.removeItem('ByerFormShipping[receiptLast-name]');
            localStorage.removeItem('ByerFormShipping[receiptAddress]');
            localStorage.removeItem('ByerFormShipping[receiptArea]');
            localStorage.removeItem('ByerFormShipping[receiptTown]');
            localStorage.removeItem('ByerFormShipping[receiptZip]');
            localStorage.removeItem('ByerFormShipping[receiptTelephone]');
            localStorage.removeItem('ByerFormShipping[receiptEmail]');

        }else if (num === 2){
            setIsResNews(true);
            //setReceiptNewYes(true);
            setIsInvoice(false);
            //setIsInvoiceYes(false);
            //setIsReceiptYes(false);

            localStorage.removeItem('ByerFormShipping[businessFirstName]');
            localStorage.removeItem('ByerFormShipping[businessLastName]');
            localStorage.removeItem('ByerFormShipping[businessName]');
            localStorage.removeItem('ByerFormShipping[businessProfession]');
            localStorage.removeItem('ByerFormShipping[businessAfm]');
            localStorage.removeItem('ByerFormShipping[businessDoy]');
            localStorage.removeItem('ByerFormShipping[businessAddress]');
            localStorage.removeItem('ByerFormShipping[businessTown]');
            localStorage.removeItem('ByerFormShipping[businessZip]');
            localStorage.removeItem('ByerFormShipping[businessTelephone]');
            localStorage.removeItem('ByerFormShipping[businessEmail]');
        }else{
            setIsInvoice(true);
            //setIsInvoiceYes(true);
            setIsResNews(false);
            //setReceiptNewYes(false);
            //setIsReceiptYes(false);

            localStorage.removeItem('ByerFormShipping[receiptFirst-name]');
            localStorage.removeItem('ByerFormShipping[receiptLast-name]');
            localStorage.removeItem('ByerFormShipping[receiptAddress]');
            localStorage.removeItem('ByerFormShipping[receiptArea]');
            localStorage.removeItem('ByerFormShipping[receiptTown]');
            localStorage.removeItem('ByerFormShipping[receiptZip]');
            localStorage.removeItem('ByerFormShipping[receiptTelephone]');
            localStorage.removeItem('ByerFormShipping[receiptEmail]');

        }
    };

    return (
        <Fragment>
            <Card>
                <div className='row'>
                    <div className='col-sm-12 col-xl-6'>
                        <Input type='text' id='first-name' name='ByerFormShipping[first-name]' label='Όνομα' placeholder='Εισάγετε το όνομα σας' value={localStorage.getItem('ByerFormShipping[first-name]')} required={true} />
                    </div>

                    <div className='col-sm-12 col-xl-6'>
                        <Input type='text' id='last-name' name='ByerFormShipping[last-name]' value={localStorage.getItem('ByerFormShipping[last-name]')} label='Επώνυμο' placeholder='Εισάγετε το επώνυμο σας' required={true} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-xl-6'>
                        <Input type='text' id='address' name='ByerFormShipping[address]' value={localStorage.getItem('ByerFormShipping[address]')} placeholder='Εισάγετε τη διεύθυνση σας' label='Διεύθυνση' required={true} />
                    </div>
                    <div className='col-sm-12 col-xl-6'>
                        <Select label='Περιφέρεια' name={`ByerFormShipping[area]`} data={stateList} number={0}  ismore='0' required={true} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-xl-8'>
                        <Input type='text' id='town' name='ByerFormShipping[town]' label='Πόλη' placeholder='Εισάγετε την πόλη σας' value={localStorage.getItem('ByerFormShipping[town]')} required={true} />
                    </div>
                    <div className='col-sm-12 col-xl-4'>
                        <Input type='number' id='zip' name='ByerFormShipping[zip]' label='T.K' placeholder='Εισάγετε το ταχυδρομικό σας κώδικα' value={localStorage.getItem('ByerFormShipping[zip]')} required={true} />
                    </div>
                    <div className='col-sm-12 col-xl-4'>
                        <Input type='number' id='telephone' name='ByerFormShipping[telephone]' label='Τηλέφωνο Επικοινωνίας' placeholder='Εισάγετε το τηλέφωνο σας' value={localStorage.getItem('ByerFormShipping[telephone]')} required={true} />
                    </div>
                    <div className='col-sm-12 col-xl-8'>
                        <Input type='email' id='email' name='ByerFormShipping[email]' label='Email' placeholder='Εισάγετε το email σας' value={localStorage.getItem('ByerFormShipping[email]')} required={true} />
                    </div>
                    <div className='col-sm-12'>
                        <TextArea className='form-control' id='' placeholder='Εισάγετε σχόλια που αφορούν την παράδοση.' rows='4' name='ByerFormShipping[message]' label='Σχόλια' value={localStorage.getItem('ByerFormShipping[message]')} required={false}></TextArea>
                    </div>
                </div>
            </Card>

            <Card>

                <div className='row'>
                    <div className='col-sm-12 col-xl-12' style={{color:'#285DAA', fontWeight:'bold', marginBottom:'20px'}}>Τύπος Παραστατικού</div>
                    <div className='col-sm-12 col-xl-2'><input type='radio' name='isinvoice' id='isinvoice0' onClick={() => {toogleInvoice(0);}} /> <label htmlFor='isinvoice0'>Απόδειξη</label></div>
                    {/*<div className='col-sm-12 col-xl-4'><input type='radio' name='isinvoice' {...(receiptNewYes && {checked:true} )} onClick={() => {toogleInvoice(2);}} /> Απόδειξη (Νέα Στοιχεία)</div>*/}
                    <div className='col-sm-12 col-xl-3'><input type='radio' name='isinvoice' id='isinvoice1' onClick={() => {toogleInvoice(1);}} /> <label htmlFor='isinvoice1'>Τιμολόγιο</label></div>
                </div>

                {isresnew &&

                    <div id='isinvoice'>
                        <div className='row'>
                            <hr style={{marginTop: '30px', marginBottom: '30px'}}/>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='first-name' name='ByerFormShipping[receiptFirst-name]' label='Όνομα' placeholder='Εισάγετε το όνομα σας' value={localStorage.getItem('ByerFormShipping[receiptFirst-name]')} required={true} />
                            </div>

                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='last-name' name='ByerFormShipping[receiptLast-name]' value={localStorage.getItem('ByerFormShipping[receiptLast-name]')} label='Επώνυμο' placeholder='Εισάγετε το επώνυμο σας' required={true} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='address' name='ByerFormShipping[receiptAddress]' value={localStorage.getItem('ByerFormShipping[receiptAddress]')} placeholder='Εισάγετε την διεύθυνση σας' label='Διεύθυνση' required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-6'>
                                <Select label='Περιφέρεια' name={`ByerFormShipping[receiptArea]`} data={stateList} number={0}  ismore='0' required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-8'>
                                <Input type='text' id='town' name='ByerFormShipping[receiptTown]' label='Πόλη' placeholder='Εισάγετε την πόλη σας' value={localStorage.getItem('ByerFormShipping[receiptTown]')} required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-4'>
                                <Input type='text' id='zip' name='ByerFormShipping[receiptZip]' label='T.K' placeholder='Εισάγετε τον ταχυδρομικό σας κώδικα' value={localStorage.getItem('ByerFormShipping[receiptZip]')} required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-4'>
                                <Input type='text' id='telephone' name='ByerFormShipping[receiptTelephone]' label='Τηλέφωνο Επικοινωνίας' placeholder='Εισάγετε το τηλέφωνο σας' value={localStorage.getItem('ByerFormShipping[receiptTelephone]')} required={true}/>
                            </div>
                            <div className='col-sm-12 col-xl-8'>
                                <Input type='email' id='email' name='ByerFormShipping[receiptEmail]' label='Email' placeholder='Εισάγετε το email σας' value={localStorage.getItem('ByerFormShipping[receiptEmail]')} required={true}/>
                            </div>
                        </div>
                    </div>
                }

                {isinvoice &&

                    <div id='isinvoice'>

                        <div className='row'>
                            <hr style={{marginTop: '30px', marginBottom: '30px'}}/>
                            <div className='col-sm-6'>
                                <Input type='text' id='businessFirstName' name='ByerFormShipping[businessFirstName]'
                                       label='Όνομα Υπευθύνου' placeholder='Εισάγετε το όνομα υπευθύνου'
                                       value={localStorage.getItem('ByerFormShipping[businessFirstName]')} required={true}/>
                            </div>

                            <div className='col-sm-6'>
                                <Input type='text' id='businessLastName' name='ByerFormShipping[businessLastName]'
                                       label='Επώνυνο Υπευθύνου' placeholder='Εισάγετε το επώνυμο υπευθύνου'
                                       value={localStorage.getItem('ByerFormShipping[businessLastName]')} required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <Input type='text' id='businessName' name='ByerFormShipping[businessName]'
                                       label='Επωνυμία Επιχείρησης' placeholder='Εισάγετε την επωνυμία επιχείρησης'
                                       value={localStorage.getItem('ByerFormShipping[businessName]')} required={true}/>
                            </div>

                            <div className='col-sm-6'>
                                <Input type='text' id='businessProfession' name='ByerFormShipping[businessProfession]'
                                       label='Δραστηριότητα' placeholder='Εισάγετε την δραστηριότητα'
                                       value={localStorage.getItem('ByerFormShipping[businessProfession]')} required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='businessAfm' name='ByerFormShipping[businessAfm]' label='ΑΦΜ'
                                       placeholder='Εισάγετε το ΑΦΜ'
                                       value={localStorage.getItem('ByerFormShipping[businessAfm]')} required={true}/>
                            </div>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='businessDoy' name='ByerFormShipping[businessDoy]' label='ΔΟΥ'
                                       placeholder='Εισάγετε την ΔΟΥ'
                                       value={localStorage.getItem('ByerFormShipping[businessDoy]')} required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='address' name='ByerFormShipping[businessAddress]'
                                       value={localStorage.getItem('ByerFormShipping[businessAddress]')} label='Διεύθυνση'
                                       placeholder='Εισάγετε την διεύθυνση' required={true}/>
                            </div>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='town' name='ByerFormShipping[businessTown]' label='Πόλη'
                                       placeholder='Εισάγετε την πόλη'
                                       value={localStorage.getItem('ByerFormShipping[businessTown]')} required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='zip' name='ByerFormShipping[businessZip]' label='T.K'
                                       placeholder='Εισάγετε τον ταχυδρομικό κώδικα'
                                       value={localStorage.getItem('ByerFormShipping[businessZip]')} required={true}/>
                            </div>
                            <div className='col-sm-12 col-xl-6'>
                                <Input type='text' id='telephone'
                                       name='ByerFormShipping[businessTelephone]' label='Τηλέφωνο Επικοινωνίας'
                                       placeholder='Εισάγετε το τηλέφωνο'
                                       value={localStorage.getItem('ByerFormShipping[businessTelephone]')} required={true}/>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-12'>
                                <Input type='text' id='telephone'
                                       name='ByerFormShipping[businessEmail]' label='Email'
                                       placeholder='Εισάγετε το email'
                                       value={localStorage.getItem('ByerFormShipping[businessEmail]')} required={true}/>
                            </div>
                        </div>

                    </div>
                }
            </Card>


            {(() => {
                if (CollapseA) {
                    return (<RadioCollapse label='Παράδοση στο χώρο μου (Οικία / Εργασία)' name='storeRadio' value='option-1' id='option-1' ischecked='checked'></RadioCollapse>)
                }else{
                    return (<RadioCollapse label='Παράδοση στο χώρο μου (Οικία / Εργασία)' name='storeRadio' value='option-1' id='option-1' ischecked='no'></RadioCollapse>)
                }
            })()}

            {(() => {
                if (CollapseB) {
                    return (
                        <RadioCollapse label='Παραλαβή από κατάστημα Γενικής Ταχυδρομικής' name='storeRadio' value='option-2' id='option-2' ischecked='checked'>
                            <Card>
                                <GenikiStores />
                            </Card>
                        </RadioCollapse>
                    )
                }else{
                    return (
                        <RadioCollapse label='Παραλαβή από κατάστημα Γενικής Ταχυδρομικής' name='storeRadio' value='option-2' id='option-2' ischecked='no'>
                            <Card>
                                <GenikiStores />
                            </Card>
                        </RadioCollapse>
                    )
                }
            })()}


        </Fragment>
    );
}

export default Step2Form;
