import classes from './RadioCollapse.module.scss';

function RadioCollapse(props) {

    let currentHeight = 0;

    if(props.ischecked === 'checked'){
        let elemcl=document.getElementById(props.id);
        if(elemcl) {
            elemcl.checked = true;
            if(props.id !== 'option-1') {
                currentHeight = 300;
            }
        }
    }

    const changeHandler = event => {

        let elemcl=document.getElementsByClassName('radioElements');
        Array.prototype.forEach.call(elemcl, function (el) {
            el.checked = false;

        });
        event.target.checked=true;

        if(props.id === 'option-1'){

            //let selectElem = document.getElementsByName('ByerFormShipping[collection-stores]');


            localStorage.removeItem('ByerFormShipping[collection-stores]');

            let elem=document.getElementsByName('ByerFormShipping[collection-stores]');
            Array.prototype.forEach.call(elem, function (el) {
                el.selectedIndex=0;
                el.required=false;
            });

            let elemcl=document.getElementById('option-2ab');
            elemcl.style.height=0;

            let elem2=document.getElementsByClassName('storeDetails');
            Array.prototype.forEach.call(elem2, function (el) {
                el.style.display = 'none';
            });
        }else{
            let elemcl=document.getElementById('option-2ab');
            elemcl.style.height='300px';
            let elem=document.getElementsByName('ByerFormShipping[collection-stores]');
            Array.prototype.forEach.call(elem, function (el) {
                el.required=true;
            });
        }
    };

    return (
        <div className={classes['radio-collapse-container']}>
            <div className={classes['radio-collapse-title']}>
                <input onClick={changeHandler} className='form-check-input radioElements' type='radio' name={props.name} id={props.id} value={props.id}  required   />
                <label htmlFor={props.id}>{props.label}</label>
            </div>

            <div className={classes['radio-collapse-content']} id={props.id+"ab"} style={{ height: `${currentHeight}px` }}>
                <div className={classes['panel-body']} >
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default RadioCollapse;
