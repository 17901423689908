import './Steps.scss';
import step1 from '../../../assets/images/svg/step-1.png';
import step2 from '../../../assets/images/svg/step-2.png';
import step3 from '../../../assets/images/svg/step-3.png';
import { ReactComponent as Divider } from '../../../assets/images/down-arrow.svg';
import CustomButton from '../../CustomButton/CustomButton';
import { Link } from 'react-router-dom';

function Steps() {
	return (
		<div className='steps-container'>
			<div className='steps-section text-center px-xl-6'>
				<div className='step-container'>
					<div className='step-image'>
						<img className='img-fluid' src={step1} alt='Geniki Taxidromiki' />
					</div>
					<div className='step-title'>Βήμα 1ο</div>
					<div className='step-description'>
						Καταχώρισε τις παραγγελίες για παραλαβή ή τα προϊόντα για αγορά που σε ενδιαφέρουν
					</div>
				</div>

				<div className='step-divider'>
					<Divider style={{width:'40px'}} />
				</div>

				<div className='step-container'>
					<div className='step-image'>
						<img className='img-fluid' src={step2} alt='Geniki Taxidromiki' />
					</div>
					<div className='step-title'>Βήμα 2ο</div>
					<div className='step-description'>
						Συμπλήρωσε τα στοιχεία παράδοσης και καταχώρισε την εντολή σου
					</div>
				</div>

				<div className='step-divider'>
					<Divider style={{width:'40px'}} />
				</div>

				<div className='step-container'>
					<div className='step-image'>
						<img className='img-fluid' src={step3} alt='Geniki Taxidromiki' />
					</div>
					<div className='step-title'>Βήμα 3ο</div>
					<div className='step-description'>
						Πλήρωσε με το Link πληρωμής που θα λάβεις και παράλαβε στην πόρτα σου
					</div>
				</div>
			</div>

			<div className='step-slogan text-center'>
				Καταχώρισε τώρα την εντολή σου και άσε τα υπόλοιπα σε εμάς!
				<div className='buttons-holder mt-3'>
					<CustomButton className='custom-button white'>
						<Link to='/entoli-agoras'>
							Εντολή Αγοράς
						</Link>
					</CustomButton>
					<CustomButton className='custom-button white'>
						<Link to='/entoli-paralavis'>
							Εντολή Παραλαβής
						</Link>
					</CustomButton>
				</div>
			</div>
		</div>
	);
}

export default Steps;
