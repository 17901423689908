import { withRouter } from 'react-router-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { MenuCategories } from './context/MenuCategories';
import TopNavigation from './components/TopNavigation/TopNavigation';
import FooterContainer from './components/FooterContainer/FooterContainer';
import Home from './Pages/Home';
import MerchantTabs from './Pages/MerchantTabs/MerchantTabs';
import Buy from './Pages/Buy';
import ViewPageContent from './Pages/ViewPageContent';
import ReceiptOrder from './Pages/ReceiptOrder';
import ScrollRestoration from 'react-scroll-restoration'
//import useGaTracker from './components/useGaTracker'

function App() {

    return (
        <BrowserRouter>
            <MenuCategories>
                <TopNavigation />
            </MenuCategories>
            <ScrollRestoration />
            <Switch>
                <Route  path='/' exact component={Home} />
                <Route  path='/entoli-agoras' exact component={Buy} />
                <Route  path='/entoli-paralavis' exact component={ReceiptOrder} />
                <Route  path='/merchants' exact component={MerchantTabs} />
                <Route  path='/:navLink' exact component={ViewPageContent} />
            </Switch>
            <FooterContainer />
        </BrowserRouter>
    );
}

export default withRouter(App);
