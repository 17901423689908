import React from 'react';
import Card from '../UI/Card/Card';
import Input from '../UI/Input/Input';
import Select from '../UI/Select/Select';
import TextArea from '../UI/TextArea/TextArea';
import Collapse from '../UI/Collapse/Collapse';
import axios from 'axios/index';
import DeleteIcon from '../assets/images/svg/delete-icon.png';
import ReactTooltip from 'react-tooltip';
import tooltipicon from '../assets/images/svg/tooltip-icon.png';
import classes from '../UI/Input/Input.module.scss';

class ReceiptForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { merch: [] };
    }

    componentDidMount() {
        this.getAllMerchants();
    }


    getAllMerchants() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(''+ API_URL +'/api/merchants?'+ API_KEY +'&filter[active]=[1]%&sort=[name_ASC]&display=full&output_format=JSON').then(response => {
            
            if (typeof response.data.gteshops_merchants_entitys === 'undefined') {
                this.setState({ merch: [] });
            } else {
                this.setState({
                    merch: response.data.gteshops_merchants_entitys,
                });
            }
        });
    }

    render() {


        let formNumbern = this.props.flag;
        formNumbern++;
        const formNumber = formNumbern + 'η';
        const classNameM = this.props.number + 'mid';

        let elsT = document.getElementsByClassName('DtaTitle');
        let p = 0;
        Array.prototype.forEach.call(elsT, function (elp) {
            p++;
            elp.innerHTML = p + 'η';
        });

        return (
            <>
                <div className={classNameM}>
                    <Card number={formNumber} title=' Παραγγελία' rel={this.props.number}>
                        <Select label='eShop / Κατάστημα Παραλαβής' data={this.state.merch} name={`form[${this.props.number}][merchant]`} ismore='1'  number={this.props.number}  required={true}/>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-11'>
                                <Input placeholder='Εισάγετε τον αριθμό παραγγελίας' label='Αριθμός Παραγγελίας' name={`form[${this.props.number}][order_number]`} value={localStorage.getItem(`form[${this.props.number}][order_number]`)} required={true}/>
                            </div>
                            <div className='col-sm-12 col-xl-1'>
                                <img
                                    width='30'
                                    className={`${classes.tooltip}`}
                                    src={tooltipicon}
                                    data-tip='Συμπληρώστε τον αριθμό παραγγελίας που σας δόθηκε <br/>από το κατάστημα που καταχωρίσατε την παραγγελία σας'
                                    data-place='right'
                                    data-background-color='#2599FC'
                                    data-text-color='#fff'
                                    data-effect='solid'
                                    data-multiline={true}
                                    alt='information button'
                                />
                                <ReactTooltip />
                            </div>
                        </div>


                        <Collapse title='Σχόλιο Παραγγελίας'>
                            <TextArea placeholder='Εισάγετε σχόλια που αφορούν την παραγγελία σας' rows='4' name={`form[${this.props.number}][order-comment]`} value={localStorage.getItem(`form[${this.props.number}][order-comment]`)} required={false}/>
                        </Collapse>

                        {(() => {
                            if(formNumbern !== 1){
                                return (
                                    <div className='text-center'  onClick={() => this.props.handler(this.props.number)}>
                                        <img src={DeleteIcon} style={{ width: '21px', cursor: 'pointer' }} alt='bin icon' />
                                    </div>
                                )
                            }
                        })()}

                    </Card>
                </div>
            </>
        );
    }
}
export default ReceiptForm;
