import classes from './HomeSection2.module.scss';
import Steps from './Steps/Steps';
import Titles from '../../UI/Titles/Titles';
import Container from '../../UI/Container/Container';
import sectionImage from '../../assets/images/home-section-1.png';
import h1Image from '../../assets/images/geniki-taxidromikih1.png';

function HomeSection2() {
	return (
		<section className={`${classes['home-section-2']} py-5`}>
			<Container>
				<Titles className='text-center px-xl-10 style-3'>
					<h1><span style={{color:'rgb(208, 18, 26)'}}>GTeShops</span> Υπηρεσία Συνδυασμένης Αποστολής από τη <br/>
						<img style={{marginTop:'10px'}}  src={h1Image} alt='Geniki Taxidromiki' /></h1>
				</Titles>
                <p className={`${classes['step-description']}`}>Συνδυάστε τις αγορές σας από διάφορα eshops, σε μία μοναδική αποστολή!</p>
				<div className={`${classes['description']} ${classes['white-color']} px-xl-15 text-center mb-4`}>
				</div>
				<Steps />
			</Container>

            <Container>
                <div className='text-center' style={{marginTop: '70px'}}>
                    <img className='img-fluid px-0 px-xl-12' alt='alt text' src={sectionImage} />
                </div>
            </Container>

		</section>
	);
}

export default HomeSection2;
