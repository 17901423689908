import React, { Fragment } from 'react';
import axios from 'axios';
import GetListingMerchants from './GetListingMerchants';
import Container from '../../UI/Container/Container';
import classes from './Merchants.module.scss';
import PageStyles from '../../UI/PageStyles/PageStyles';
import { withRouter } from 'react-router-dom';
import autoBind from 'auto-bind';
import Carousel from 'react-material-ui-carousel';
import { Grid, Card } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class MerchantsAlphabetical extends React.Component {

    constructor(props) {
        super(props);
        this.state = {catId: '',
            groupedLetters: [],
            enLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            grLetters:['Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ', 'Ν', 'Ξ', 'Ο', 'Π', 'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω'],
            newitemscat: [],
            activeIndex: null,
            autoPlay: false,
            animation: 'fade',
            indicators: false,
            timeout: 500,
            navButtonsAlwaysVisible: true,
            navButtonsAlwaysInvisible: false,
            cycleNavigation: true,
        };
        autoBind(this);
    }

    componentDidMount() {
        this.getLetters();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.getLetters();
        }
    }

    handleClick(id) {
        this.setState({ catId: id });
    }

    getLetters() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(''+ API_URL +'/api/getAllLetters?'+ API_KEY +'&display=full&output_format=AUTO').then(response => {


            if( typeof (response.data.letters) === 'undefined' ){
                console.log('noletters');
                return;
            }

            this.setState({groupedLetters: response.data.letters});
            
            //split array by 6
            let chunkSize = 0;
            let screenWidth= parseInt(window.screen.width);

            if(screenWidth <= 764) {
                chunkSize = 6;

                let i=0;
                let p=0;
                let newArray=[];

                const groups = this.state.groupedLetters
                    .map((e, i) => {
                        return i % chunkSize === 0 ? this.state.groupedLetters.slice(i, i + chunkSize) : null;
                    })
                    .filter(e => {
                        return e;
                    });                                  
                    
                for(i=0; i< groups.length; i++){
                    for(p=0; p < groups[i].length; p++) {
                        if(groups[i][p]['id'] != this.state.catId){
                            newArray.push(groups[i][p]);
                        }
                    }
                    for(p=0; p < groups[i].length; p++) {
                        if(groups[i][p]['id'] == this.state.catId){
                            newArray.unshift(groups[i][p]);
                            this.setState({activeIndex: groups[i][p]['id']});
                        }
                    }
                }

                const groups2 = newArray
                    .map((e, i) => {
                        return i % chunkSize === 0 ? newArray.slice(i, i + chunkSize) : null;
                    })
                    .filter(e => {
                        return e;
                    });
                
                this.setState({ newitemscat: groups2 });
            }

        });
    }

    render() {

        let screenWidth= parseInt(window.screen.width);

        return (
            <Fragment>
                <div className='alphaContainer'>
                    <Container className='py-3'>
                        <div className={classes.alphaEngine}>
                            {(() => {
                                
                                if(screenWidth <= 764) {
                                    return (
                                        <>
                                        <div className={classes.alphavetMobile}>
                                            <div className={this.state.catId === 0 ? ` ${classes.active}` : ''} onClick={() => this.handleClick(0)}>ΟΛΑ</div>
                                            <div className={this.state.catId === 'num' ? ` ${classes.active}` : ''} onClick={() => this.handleClick('num')}>0-9</div>
                                        </div>

                                        <div style={{width:'400px', lineHeight:'30px', margin:'auto'}}>

                                            <Carousel
                                                navButtonsProps={{
                                                    style: {
                                                        backgroundColor: 'transparent',
                                                        borderRadius: 0,
                                                        color: '#285DAA',
                                                        margin: '0',
                                                        padding: '0',
                                                    },
                                                }}
                                                NextIcon={<ArrowForwardIosIcon />}
                                                PrevIcon={<ArrowBackIosIcon />}
                                                autoPlay={this.state.autoPlay}
                                                animation={this.state.animation}
                                                indicators={this.state.indicators}
                                                timeout={this.state.timeout}
                                                cycleNavigation={this.state.cycleNavigation}
                                                navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                                                navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                                            >
                                                {this.state.newitemscat.map((itemCard, index) => (
                                                    <Card key={index}raised className='Banner'>
                                                        <Grid container spacing={1} className='BannerGrid'>
                                                            {itemCard.map((item, index2) => (
                                                                /*<Grid key={index2} item xs={12 / this.chunkSize} >*/
                                                                <Grid key={index2} item>
                                                                    <div className={`text-center`} style={{ cursor: 'pointer' }}>
                                                                        <div className='d-flex flex-column'>
                                                                            <div style={{fontFamily: 'CF Asty Std Bold',marginRight:'10px',marginLeft:'10px'}} className={item.replaceAll('"', '') === this.state.catId ? `${classes.active}` : ''} onClick={() => this.handleClick(item.replaceAll('"', ''))}>{item.replaceAll('"', '')}</div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Card>
                                                ))}
                                            </Carousel>

                                        </div>
                                        </>
                                    );
                                }else{
                                    return (
                                    <>
                                        <div className={classes.alphavetAll}>
                                            <div className={this.state.catId === 0 ? ` ${classes.active}` : ''} onClick={() => this.handleClick(0)}>ΟΛΑ</div>
                                        </div>

                                        <div className={classes.alphavetEl}>
                                            <div className={classes.alphavetEn}>
                                                {this.state.enLetters.map((element, index) => {
                                                    if (this.state.groupedLetters.includes(element)) {
                                                        return (
                                                            <div key={index} className={element === this.state.catId ? `${classes.active}` : ''} onClick={() => this.handleClick(element)}>{element}</div>
                                                        );
                                                    }else{
                                                        return (
                                                            <div key={index} className={classes.inActive} >{element}</div>
                                                        );
                                                    }
                                                })}
                                            </div>

                                            <div className={classes.alphavetGr}>
                                                {this.state.grLetters.map((element, index) => {
                                                    if (this.state.groupedLetters.includes(element)) {
                                                        return (
                                                            <div key={index} className={element === this.state.catId ? `${classes.active}` : ''} onClick={() => this.handleClick(element)}>{element}</div>
                                                        );
                                                    }else{
                                                        return (
                                                            <div key={index} className={classes.inActive} >{element}</div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>

                                        <div className={classes.alphavetAll}>
                                            <div className={this.state.catId === 'num' ? ` ${classes.active}` : ''} onClick={() => this.handleClick('num')}>0-9</div>
                                        </div>
                                    </>

                                    );
                                }

                            })()}


                        </div>
                    </Container>
                </div>
                <PageStyles className='py-4'>
                    <GetListingMerchants catId={this.state.catId} />
                </PageStyles>
            </Fragment>
        );
    }
}

export default withRouter(MerchantsAlphabetical);

