import React from 'react';
import { Fragment } from 'react';
import Titles from '../UI/Titles/Titles';
import TextArea from '../UI/TextArea/TextArea';
import Card from '../UI/Card/Card';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import axios from "axios/index";
import Alert from "../components/Alerts/Alert";
import qs from 'qs';
import classes from '../UI/Select/Select.module.scss';
import Container from '../UI/Container/Container';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = { successmsg: false, errormsg:false, msg:'' };
        this.sendData = this.sendData.bind(this);
    }

    sendData(event) {
        event.preventDefault();
        let myarray=[];
        const tempPlayer = new FormData(event.target);
        for (let [key, value] of tempPlayer.entries()) {
            if( value !== 0 ) {
                myarray[key]=value;
            }
        }

        let emailBody = qs.stringify({
            'service': 'submitCf7',
            '_etscf7_container_post': '1',
            '_etscf7_unit_tag': 'test',
            'gt-name': myarray['contactName'],
            'gt-email': myarray['contactEmail'],
            'gt-phone': myarray['contactTelephone'],
            'gt-order': myarray['contactNumber'],
            'gt-subject': myarray['contantReason'],
            'gt-message': myarray['contactMessage']
        });

        const API_URL = `${process.env.REACT_APP_URL}`;
        
        let config = {
            method: 'post',
            url: ''+ API_URL +'/gt/api/',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : emailBody
        };

        axios(config)
        .then(response => {
                if(response.data.status === "success"){

                    if(typeof response.data.invalidFields == "undefined"){
                        this.setState({successmsg: true});
                        this.setState({msg: response.data.message});
                    }else if (response.data.invalidFields.length > 0 ) {
                        this.setState({ errormsg: true });
                        this.setState({ msg: response.data.message });
                    }else {
                        this.setState({errormsg: true});
                        this.setState({msg: 'Υπάρχει κάποιο πρόβλημα κατα την αποστολή. Παρακαλώ προσπαθήστε αργότερα'});
                    }
                }
            }
        )
        .catch(error => {
            console.error('There was an error!', error);
        });

    }

    render() {

        if(this.state.successmsg){
            return (<><Alert className='alert alert-success'  message={this.state.msg} /></>)
        }

        return (

            <Fragment>
                {this.state.errormsg === true &&
                    <Alert className='alert alert-success'  message={this.state.msg} />
                }


                <Container>
                    <div style={{maxWidth:'780px', margin:'auto', paddingBottom:'50px'}}>
                        <Titles className='pb-3'>
                            <h2>Φόρμα Επικοινωνίας</h2>
                            <p>
                                Επικοινώνησε μαζί μας για οποιαδήποτε απορία έχεις σχετικά
                                με τη λειτουργία του gteshops.com
                            </p>
                        </Titles>
                        <form onSubmit={this.sendData}>
                            <Card>
                                <Input name='contactName' type='text' id='contact-fullname' label='Ονοματεπώνυμο' placeholder='Εισάγετε το ονοματεπώνυμό σας' required={true}/>
                                <Input name='contactEmail' type='email' id='contact-email' label='Email' placeholder='Εισάγετε το email σας' value='' required={true}/>
                                <Input name='contactTelephone' type='text' id='contact-telephone'
                                       label='Τηλέφωνο Επικοινωνίας' placeholder='Εισάγετε το τηλέφωνό σας' required={true} minlength='10'/>

                                <div className={classes['select-bottom-spacer-25']}>
                                    <label htmlFor='disabledSelect' className={classes.label}>
                                        Θέμα Επικοινωνίας
                                    </label>
                                    <select defaultValue={''}  name='contantReason' className={`${classes.select} form-select`} aria-label='select-shop' required={true}>
                                        <option value="" disabled>Επιλέξτε</option>
                                        <option value='Εντολή Αγοράς Προϊόντων'>Εντολή Αγοράς Προϊόντων</option>
                                        <option value='Εντολή Παραλαβής Παραγγελιών'>Εντολή Παραλαβής Παραγγελιών</option>
                                        <option value='Άλλο'>Άλλο</option>
                                    </select></div>

                                <Input name='contactNumber' type='text' id='contact-product-id'
                                       label='Αριθμός Εντολής' placeholder='Εισάγετε τον αριθμό εντολής (αγοράς ή παραλαβής)' required={false}/>
                                <TextArea name='contactMessage' label='Μήνυμα' rows='4' placeholder='Εισάγετε το μήνυμά σας' required={true}/>
                            </Card>
                            <div className='float-end'>
                                <Button className='btn form-button' type='submit'>Αποστολή Αιτήματος</Button>
                            </div>
                        </form>
                    </div>
                </Container>
            </Fragment>
        )
}

}


export default Contact;
