import React, { Fragment, useState } from 'react';
import Container from '../UI/Container/Container';
import Titles from '../UI/Titles/Titles';
import ContainerDivider from '../UI/ContainerDivider/ContainerDivider';
import ProcessOrder from './ProcessOrder';
import { makeStyles } from '@material-ui/core/styles';
import StepTwoForm from '../components/ReceiptStepper/StepTwoForm';
import StepThreeForm from '../components/ReceiptStepper/StepThreeForm';
import Step1 from './Step1Receipt';
import {Button, Stepper, Step, StepLabel} from '@material-ui/core';
import {useForm, FormProvider } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    button: {
        borderRadius: '27px',
        fontSize: '16px',
        textTransform: 'none',
        padding: '15px 40px',
        fontFamily: 'CF Asty Std Bold',
        lineHeight: '1rem'
    }
}));

function getSteps() {
    return [
        `Βήμα 1ο Συμπλήρωση Αίτησης`,
        'Βήμα 2ο Στοιχεία Πελάτη',
        'Βήμα 3ο Καταχώριση Εντολής Παραλαβής'
    ];
}

const Step2 = () => {
    return (
        <Container className='px-xl-17'>
            <ContainerDivider className='pt-4 pb-3'>
                <Titles>
                    <h2>Στοιχεία Πελάτη</h2>
                    <p>Συμπλήρωστε τα στοιχεία σας και επιλέξτε τύπο παραστατικού και τρόπο αποστολής.</p>
                </Titles>
            </ContainerDivider>
            <StepTwoForm />
        </Container>
    );
};
const Step3 = () => {
    return (
        <Container className='px-xl-8'>
            <ContainerDivider className='pt-4 pb-3'>
                <Titles>
                    <h2>Καταχώριση Εντολής Παραλαβής</h2>
                    <p>Σύνοψη Στοιχείων Εντολής Παραλαβής</p>
                </Titles>
            </ContainerDivider>
            <StepThreeForm />
        </Container>
    );
};

function getStepContent(step) {
    switch (step) {
        case 0:
            return <Step1 />;
        case 1:
            return <Step2 />;
        case 2:
            return <Step3 />;
        default:
            return 'unknown step';
    }
}

const LinaerStepper = event => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();
    const methods = useForm({});

    const handleNext = (data, event) => {
        window.scrollTo(0,0);
        setTimeout(() => {
            if (activeStep === steps.length - 1) {
                setActiveStep(activeStep + 1);
            } else {
                setActiveStep(activeStep + 1);
                setSkippedSteps(skippedSteps.filter(skipItem => skipItem !== activeStep));
            }
        }, 100);
    };

    const handleBack = () => {
        window.scrollTo(0,0);
        setTimeout(() => {
            setActiveStep(activeStep - 1);
        }, 100);
    };

    return (
        <Fragment>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                    const labelProps = {};
                    const stepProps = {};
                    return (
                        <Step {...stepProps} key={index}>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <h3 align='center'>
                    <ProcessOrder />
                </h3>
            ) : (

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleNext)}>
                        {getStepContent(activeStep)}
                        <ContainerDivider className='py-2'>
                            <div className='text-center'>
                                <Button
                                    className={classes.button}
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Προηγούμενο
                                </Button>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                >
                                    {activeStep === steps.length - 1
                                        ? 'Ολοκλήρωση'
                                        : 'Επόμενο'}
                                </Button>
                            </div>
                        </ContainerDivider>
                    </form>
                </FormProvider>

            )}
        </Fragment>
    );
};

export default LinaerStepper;
