import React, { Component } from 'react';
import axios from 'axios';
import { ReactComponent as CommentIcon } from '../assets/images/svg/comment-icon.svg';
import ReactTooltip from 'react-tooltip';
import Container from '../UI/Container/Container';
import ContainerDivider from '../UI/ContainerDivider/ContainerDivider';
import Titles from '../UI/Titles/Titles';


class ProcessOrder extends Component {

    constructor(props) {
        super(props);
        this.state = { postCustomerId: '', postAddressId: '', postCartId: '', postOrderId: ''};
    }

    componentDidMount() {
        this.getMerc();
    }

    getMerc() {

        let myarr = [0];
        let totalPrice = 0;
        let orderMessage = '';
        let selectShipping = '';

        if (typeof localStorage.getItem('ByerFormShipping[message]') !== 'undefined' && localStorage.getItem('ByerFormShipping[message]') !== null) {
            orderMessage=localStorage.getItem('ByerFormShipping[message]').replaceAll('"', '');
        }

        if (typeof localStorage.getItem('ByerFormShipping[selectShipping]') !== 'undefined' && localStorage.getItem('ByerFormShipping[selectShipping]') !== null) {
            selectShipping=localStorage.getItem('ByerFormShipping[selectShipping]').replaceAll('"', '');
        }

        if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
            if (localStorage.getItem('ByerFormCount').includes('-')) {
                let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
                myarr = allReadyKeys.split('-');
            }
        }

        Object.keys(myarr).map(function (p) {
            if (typeof localStorage.getItem('form[' + p + '][product-price]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-price]') !== null) {
                totalPrice += parseInt(localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', ''));
            }
            return totalPrice;
        });


        let orderData = [
            ['employeeId',1],
            ['orderMessage',orderMessage],
            ['ps_checkpayment','ps_checkpayment'],
            ['orderStateId',3],
            ['selectShipping',selectShipping],
            ['products',[]]
        ];

        if (typeof localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== 'undefined' && localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== null) {
            let customerData = [['customer',
                ['firstName', localStorage.getItem('ByerFormShipping[receiptFirst-name]').replaceAll('"', '')],
                ['lastName', localStorage.getItem('ByerFormShipping[receiptLast-name]').replaceAll('"', '')],
                ['email', localStorage.getItem('ByerFormShipping[receiptEmail]').replaceAll('"', '')],
                ['password', '111'],
                ['defaultGroupId', 1],
                ['shopId', 1]
            ]];
            orderData.push(customerData);

            if (typeof localStorage.getItem('ByerFormShipping[genikiAddress]') !== 'undefined' && localStorage.getItem('ByerFormShipping[genikiAddress]') !== null) {

                let addressData = [['address',
                    ['addressAlias','Geniki'],
                    ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address','Γενική Ταχυδρομική '+localStorage.getItem('ByerFormShipping[genikiAddress]').replaceAll('"', '')],
                    ['city',localStorage.getItem('ByerFormShipping[genikiCity]').replaceAll('"', '')],
                    ['countryId',9],
                    ['state',0],
                    ['postcode',localStorage.getItem('ByerFormShipping[genikiPostCode]').replaceAll('"', '')],
                    ['phone_mobile',localStorage.getItem('ByerFormShipping[genikiPhone]').replaceAll('"', '')]
                ]];
                orderData.push(addressData);

            }else {

                let addressData = [['address',
                    ['addressAlias', localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['firstName', localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName', localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address', localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['city', localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')],
                    ['countryId', 9],
                    ['state',localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')],
                    ['postcode', localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')],
                    ['phone_mobile', localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')]
                ]];

                orderData.push(addressData);
            }

            let addressChargeData = [['addressCharge',
                ['addressAlias',localStorage.getItem('ByerFormShipping[receiptAddress]').replaceAll('"', '')],
                ['firstName',localStorage.getItem('ByerFormShipping[receiptFirst-name]').replaceAll('"', '')],
                ['lastName',localStorage.getItem('ByerFormShipping[receiptLast-name]').replaceAll('"', '')],
                ['address',localStorage.getItem('ByerFormShipping[receiptAddress]').replaceAll('"', '')],
                ['city',localStorage.getItem('ByerFormShipping[receiptTown]').replaceAll('"', '')],
                ['countryId',9],
                ['postcode',localStorage.getItem('ByerFormShipping[receiptZip]').replaceAll('"', '')],
                ['phone_mobile',localStorage.getItem('ByerFormShipping[receiptTelephone]').replaceAll('"', '')]
            ]];

            orderData.push(addressChargeData);


        }else if (typeof localStorage.getItem('ByerFormShipping[businessName]') !== 'undefined' && localStorage.getItem('ByerFormShipping[businessName]') !== null) {
            let customerData = [['customer',
                ['firstName',localStorage.getItem('ByerFormShipping[businessFirstName]').replaceAll('"', '')],
                ['lastName',localStorage.getItem('ByerFormShipping[businessLastName]').replaceAll('"', '')],
                ['email',localStorage.getItem('ByerFormShipping[businessEmail]').replaceAll('"', '')],
                ['password','111'],
                ['defaultGroupId',1],
                ['shopId',1]
            ]];
            orderData.push(customerData);

            if (typeof localStorage.getItem('ByerFormShipping[genikiAddress]') !== 'undefined' && localStorage.getItem('ByerFormShipping[genikiAddress]') !== null) {

                let addressData = [['address',
                    ['addressAlias','Geniki'],
                    ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address','Γενική Ταχυδρομική '+localStorage.getItem('ByerFormShipping[genikiAddress]').replaceAll('"', '')],
                    ['city',localStorage.getItem('ByerFormShipping[genikiCity]').replaceAll('"', '')],
                    ['countryId',9],
                    ['state',localStorage.getItem('ByerFormShipping[genikiState]').replaceAll('"', '')],
                    ['postcode',localStorage.getItem('ByerFormShipping[genikiPostCode]').replaceAll('"', '')],
                    ['phone_mobile',localStorage.getItem('ByerFormShipping[genikiPhone]').replaceAll('"', '')]
                ]];
                orderData.push(addressData);

            }else {

                let addressData = [['address',
                    ['addressAlias', localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['firstName', localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName', localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address', localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['city', localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')],
                    ['countryId', 9],
                    ['state',localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')],
                    ['postcode', localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')],
                    ['phone_mobile', localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')]
                ]];

                orderData.push(addressData);
            }

            let addressChargeData = [['addressCharge',
                ['addressAlias',localStorage.getItem('ByerFormShipping[businessAddress]').replaceAll('"', '')],
                ['firstName',localStorage.getItem('ByerFormShipping[businessFirstName]').replaceAll('"', '')],
                ['lastName',localStorage.getItem('ByerFormShipping[businessLastName]').replaceAll('"', '')],
                ['address',localStorage.getItem('ByerFormShipping[businessAddress]').replaceAll('"', '')],
                ['city',localStorage.getItem('ByerFormShipping[businessTown]').replaceAll('"', '')],
                ['countryId',9],
                ['state',0],
                ['postcode',localStorage.getItem('ByerFormShipping[businessZip]').replaceAll('"', '')],
                ['phone_mobile',localStorage.getItem('ByerFormShipping[businessTelephone]').replaceAll('"', '')],
                ['businessName',localStorage.getItem('ByerFormShipping[businessName]').replaceAll('"', '')],
                ['businessProfession',localStorage.getItem('ByerFormShipping[businessProfession]').replaceAll('"', '')],
                ['businessAfm',localStorage.getItem('ByerFormShipping[businessAfm]').replaceAll('"', '')],
                ['businessDoy',localStorage.getItem('ByerFormShipping[businessDoy]').replaceAll('"', '')]
            ]];
            orderData.push(addressChargeData);
        }else{
            let customerData = [['customer',
                ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                ['email',localStorage.getItem('ByerFormShipping[email]').replaceAll('"', '')],
                ['password','111'],
                ['defaultGroupId',1],
                ['shopId',1]
            ]];
            orderData.push(customerData);

            if (typeof localStorage.getItem('ByerFormShipping[genikiAddress]') !== 'undefined' && localStorage.getItem('ByerFormShipping[genikiAddress]') !== null) {

                let addressData = [['address',
                    ['addressAlias','Geniki'],
                    ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address','Γενική Ταχυδρομική '+localStorage.getItem('ByerFormShipping[genikiAddress]').replaceAll('"', '')],
                    ['city',localStorage.getItem('ByerFormShipping[genikiCity]').replaceAll('"', '')],
                    ['countryId',9],
                    ['state',localStorage.getItem('ByerFormShipping[genikiState]').replaceAll('"', '')],
                    ['postcode',localStorage.getItem('ByerFormShipping[genikiPostCode]').replaceAll('"', '')],
                    ['phone_mobile',localStorage.getItem('ByerFormShipping[genikiPhone]').replaceAll('"', '')]
                ]];
                orderData.push(addressData);
            }else{

                let addressData = [['address',
                    ['addressAlias',localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                    ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                    ['address',localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                    ['city',localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')],
                    ['countryId',9],
                    ['state',localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')],
                    ['postcode',localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')],
                    ['phone_mobile',localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')]
                ]];
                orderData.push(addressData);
            }

            let addressChargeData = [['addressCharge',
                ['addressAlias',localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                ['firstName',localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')],
                ['lastName',localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')],
                ['address',localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')],
                ['city',localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')],
                ['countryId',9],
                ['state',localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')],
                ['postcode',localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')],
                ['phone_mobile',localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')]
            ]];
            orderData.push(addressChargeData);
        }

        let customizations = [];
        let products = [];
        let productComment='';
        Object.keys(myarr).map(function (p) {

            if (typeof localStorage.getItem('form[' + p + '][product-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-comment]') !== null) {
                productComment = localStorage.getItem('form[' + p + '][product-comment]').replaceAll('"', '');
            }

            customizations = [];
            if (typeof localStorage.getItem('form[' + p + '][product-price]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-price]') !== null) {
                products.push(['id',3]);
                products.push(['price', localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '')]);
                products.push(['quantity', localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', '')]);
                products.push(['combination',0]);

                customizations.push('');
                let NewMerchantData='';
                if(localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '') === "-1"){
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newAddress]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newPc]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTown]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTelephone]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newEmail]').replaceAll('"', '')+"";
                    customizations.push(NewMerchantData);
                }else{
                    customizations.push(localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', ''));
                }

                customizations.push(localStorage.getItem('form[' + p + '][product-name]').replaceAll('"', ''));
                customizations.push(localStorage.getItem('form[' + p + '][product-link]').replaceAll('"', ''));
                customizations.push('');
                customizations.push('');
                customizations.push(productComment);
                products.push(customizations);

            }else{
                products.push(['id',1]);
                products.push(['price', 0]);
                products.push(['quantity', 1]);
                products.push(['combination',0]);

                customizations.push('');
                customizations.push('');
                customizations.push('');
                customizations.push('');
                customizations.push('');
                customizations.push('');
                customizations.push('');

                let NewMerchantData='';
                if(localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '') === "-1"){
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newAddress]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newPc]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTown]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newTelephone]').replaceAll('"', '')+" - ";
                    NewMerchantData +=localStorage.getItem('form[' + p + '][newEmail]').replaceAll('"', '')+"";
                    customizations.push(NewMerchantData);
                }else{
                    customizations.push(localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', ''));
                }
                customizations.push('');
                customizations.push(localStorage.getItem('form[' + p + '][order_number]').replaceAll('"', ''));
                customizations.push(localStorage.getItem('form[' + p + '][order-comment]').replaceAll('"', ''));
                products.push(customizations);
            }
            return products;
        });

        orderData.push(products);

        //console.log(JSON.stringify({ ...orderData}));
        //console.table(orderData);
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        //Add Order
        axios
            .post(
                ''+ API_URL +'/api/gteshopOrder?'+ API_KEY +'&display=full&output_format=AUTO',
                JSON.stringify({ ...orderData})
            )
            .then(response => {
                //console.log(response.data);
                this.setState({ postOrderId: response.data.split('{"gteshoporder":"')[1].split('"}')[0]});
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    render() {

        let myarr = [0];
        let totalprice = 0;

        if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
            if (localStorage.getItem('ByerFormCount').includes('-')) {
                let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
                myarr = allReadyKeys.split('-');
            }
        }

        if (typeof localStorage.getItem('form[0][product-price]') !== 'undefined' && localStorage.getItem('form[0][product-price]') !== null) {
            return (
                <Container className='px-1 px-xl-8'>
                    <ContainerDivider className='pt-4 pb-3'>
                        <Titles>
                            <h2>Ευχαριστούμε για την καταχώριση της εντολής σου με αριθμό #{this.state.postOrderId}</h2>
                            <p>Σύντομα κάποιος εκπρόσωπος μας θα επικοινωνήσει μαζί σου είτε τηλεφωνικά είτε ηλεκτρονικά
                                για να σε ενημερώσει για την διαδικασία.</p>
                        </Titles>
                    </ContainerDivider>
                    <div className='stepper-table'>
                        <div className='d-none d-xl-block'>
                            <div className='table-grid'>

                                <div className='table-title bformc'>
                                    <div>Α/Α</div>
                                    <div>Κατάστημα</div>
                                    <div>Προϊόν</div>
                                    <div>Link Προϊόντος</div>
                                    <div>ΤΜΧ</div>
                                    <div>Αξία</div>
                                    <div>Σχόλιο</div>
                                </div>
                                {(() => {
                                    let container = [];
                                    let z = 0;
                                    let tprice='';
                                    let titleMerchant='';
                                    let productComment='';

                                    Object.keys(myarr).map(function (p) {
                                        z++;
                                        tprice=localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '');
                                        totalprice += parseFloat(tprice.replaceAll(',', '.')) * parseInt(localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', ''));

                                        if(localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '') === "-1"){
                                            titleMerchant=localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                        }else{
                                            titleMerchant=localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '');
                                        }

                                        if (typeof localStorage.getItem('form[' + p + '][product-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-comment]') !== null) {
                                            productComment = localStorage.getItem('form[' + p + '][product-comment]').replaceAll('"', '');
                                        }

                                        container.push(
                                            <div className='table-body bformc'>
                                                <div className='item'>{z}</div>
                                                <div className='item'>{titleMerchant}</div>
                                                <div className='item'>{localStorage.getItem('form[' + p + '][product-name]').replaceAll('"', '')}</div>
                                                <div className='item'><a target='_blank' rel='noreferrer' href={localStorage.getItem('form[' + p + '][product-link]').replaceAll('"', '')}>Σύνδεσμος</a>
                                                </div>
                                                <div className='item'>{localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', '')}</div>
                                                <div className='item'>{localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '')}€
                                                </div>
                                                <div className='item'>
                                                    <CommentIcon
                                                        data-tip={productComment}
                                                        data-place='left' data-background-color='#2599FC'
                                                        data-text-color='#fff' data-effect='solid' data-multiline={true}
                                                        alt='information button'/>
                                                    <ReactTooltip/>
                                                </div>
                                            </div>
                                        )

                                    })
                                    return container;

                                })()}
                            </div>
                        </div>


                        <div className='table-summary total-value'>
                            <div className='table-summary-label'>Γενικό Σύνολο</div>
                            <div className='table-summary-value'>{totalprice.toFixed(2)} €</div>
                        </div>
                    </div>
                </Container>
            );
        }else{
            return (
                <Container className='px-xl-8'>
                    <ContainerDivider className='pt-4 pb-3'>
                        <Titles>
                            <h2>Ευχαριστούμε για την καταχώριση της εντολής σου με αριθμό #{this.state.postOrderId}</h2>
                            <p>Σύντομα κάποιος εκπρόσωπος μας θα επικοινωνήσει μαζί σου είτε τηλεφωνικά είτε ηλεκτρονικά
                                για να σε ενημερώσει για την διαδικασία.</p>
                        </Titles>
                    </ContainerDivider>

                    <div className='stepper-table'>
                        <div className='d-none d-xl-block'>
                            <div className='table-grid'>
                                <div className='table-title'>
                                    <div>Α/Α</div>
                                    <div>Κατάστημα</div>
                                    <div>Αρ. Παραγγελίας</div>
                                    <div>Σχόλιο</div>
                                </div>

                                {(() => {
                                    let container = [];
                                    let z = 0;
                                    let titleMerchant='';
                                    Object.keys(myarr).map(function (p) {
                                        z++;
                                        totalprice += 0;

                                        if(localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '') === "-1"){
                                            titleMerchant=localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                        }else{
                                            titleMerchant=localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '');
                                        }

                                        container.push(
                                            <div className='table-body'>
                                                <div className='item'>{z}</div>
                                                <div className='item'>{titleMerchant}</div>
                                                <div className='item'>{localStorage.getItem('form[' + p + '][order_number]').replaceAll('"', '')}</div>
                                                <div className='item'>
                                                    <CommentIcon data-tip={localStorage.getItem('form[' + p + '][order-comment]').replaceAll('"', '')} data-place='left' data-background-color='#2599FC' data-text-color='#fff' data-effect='solid' data-multiline={true} alt='information button' />
                                                    <ReactTooltip />
                                                </div>
                                            </div>
                                        )
                                        return container;
                                    })
                                    return container;
                                })()}
                            </div>
                        </div>
                    </div>

                </Container>
            );

        }
    }
}

export default ProcessOrder;
