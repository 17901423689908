import React from 'react';
import Titles from '../../UI/Titles/Titles';
import Card from '../../UI/Card/Card';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import axios from "axios/index";
import qs from 'qs';
import Alert from "../../components/Alerts/Alert";
import Container from '../../UI/Container/Container';
import Select from '../../UI/Select/Select';
import classes from '../../UI/Input/Input.module.scss';
import contactImage from '../../assets/images/contact-img.png';
import MultiSelect from "../../UI/Select/MultiSelect";


class CooperationRequestForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { successmsg: false, errormsg:false, msg:'', stateList: [], file: null, base64URL: "", base64Data: "", optionSelected: null, categories: [], nochildern : []};
        this.sendData = this.sendData.bind(this);
    }

    componentDidMount() {
        this.getAllStates();
        this.getAllCategories();
    }

    getBase64 = file => {
        return new Promise(resolve => {
            //let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };
            //console.log(fileInfo);
        });
    };

    handleFileInputChange = e => {

        let { file } = this.state;

        file = e.target.files[0];

        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0])
        img.onload = () => {
            if(img.width !== 200 && img.height !== 200) {
                document.getElementById('lgo_upload').value= null;
                window.alert("Ανεβάστε αρχείο 200 x 200 pixels");
                return false;
            }
        }
        if (file.size > 0.1e6) {
            document.getElementById('lgo_upload').value= null;
            window.alert("Ανεβάστε αρχείο μικρότερο από 100 Kb");
            return false;
        }else if (file.type !== 'image/jpeg') {
            window.alert("Ανεβάστε αρχείο .jpg");
            return false;
        }else{
            this.getBase64(file)
                .then(result => {
                    file["base64"] = result;
                    this.setState({base64Data: result});

                    this.setState({
                        base64URL: result,
                        file
                    });
                })
                .catch(err => {
                    console.log(err);
                });

            this.setState({
                file: e.target.files[0]
            });
        }
    };

    getAllStates() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(`${API_URL}/api/states?${API_KEY}&filter[id_country]=9&filter[active]=[1]%&sort=[name_ASC]&display=full&output_format=JSON`).then(response => {
            if (typeof response.data.states === 'undefined') {
                this.setState({ stateList: [] });
            } else {
                this.setState({
                    stateList: response.data.states,
                });
            }
        });
    }

    getAllCategories() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(`${API_URL}/api/getParentChildCategories?${API_KEY}&display=full&output_format=auto`).then(response => {
            if (typeof response.data.Categories === 'undefined') {
                this.setState({ categories: [] });
            } else {
                this.setState({
                    categories: response.data.Categories,
                });
            }

            let children_elements=[];
            let parents_ids=[];
            let parentChildren = [];
            let nochildren_ids = [];
            let options = this.state.categories;

            for (const property in options) {
                if(options[property].parent_id === 0){
                    parents_ids.push(options[property].cat_id);
                }else{
                    children_elements.push(options[property]);
                }
            }

            for(let key in parents_ids ){
                let parent_id = parents_ids[key];
                let childrenArr = [];
                for(let key in children_elements ){
                    if ( children_elements[key].parent_id == parent_id ){
                        childrenArr.push(children_elements[key]);
                    }
                }
                parentChildren[parent_id] = childrenArr;
            }

            for (const property in parentChildren){
                if(!parentChildren[property].length){
                    nochildren_ids.push( Number(property));
                }
            }
            this.setState({nochildern: nochildren_ids});
            //console.log(nochildren_ids);

        });
    }

    sendData(event) {

        event.preventDefault();

        let myarray=[];
        const tempPlayer = new FormData(event.target);
        let categoriesArr = [];

        for (let [key, value] of tempPlayer.entries()) {
            if( value !== 0 ) {
                myarray[key]=value;
            }
            if ( key === 'categories' ){
                categoriesArr.push(value);
            }
        }
        myarray['categories'] = categoriesArr;

        let datatosend = {
            'gtname': myarray['nameSurname'],
            'gtcompany': myarray['business'],
            'gtcompanyname': myarray['business_name'],
            'gtactivity': myarray['profession'],
            'gtvat': myarray['vat'],
            'gtaddress': myarray['address'],
            'gtstate': myarray['nomos'],
            'gtcity': myarray['city'],
            'gtzip': myarray['pc'],
            'gtphone': myarray['telephone'],
            'gtemail': myarray['email'],
            'gtwebsite': myarray['website'],
            'gtpartner':  myarray['customer'],
            'gtimage': ''+this.state.base64Data+'',
            'gtcategories' : categoriesArr,
            'gtextracategory': myarray['extra-category']
        };

        //console.log(datatosend);

        const API_URL = `${process.env.REACT_APP_URL}`;
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        let config_send = {
            method: 'post',
            url: ''+ API_URL +'/api/addmerchants?'+API_KEY+'&display=full&output_format=AUTO',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : datatosend
        };
        axios (config_send).then(response => {
            console.log(response);
            if(response.data.error === 0){

                this.setState({successmsg: true});
                this.setState({msg: 'Έχουμε παραλάβει την αίτηση καταχώρησής σας. Θα ενημερωθείτε εκ νέου όταν ενεργοποιηθεί η καταχώρησή σας.'});

                let emailBody = qs.stringify({
                    'service': 'submitCf7',
                    '_etscf7_container_post': '2',
                    '_etscf7_unit_tag': 'test',
                    'gt-name': myarray['nameSurname'],
                    'gt-company': myarray['business'],
                    'gt-company-name': myarray['business_name'],
                    'gt-activity': myarray['profession'],
                    'gt-vat': myarray['vat'],
                    'gt-address': myarray['address'],
                    'gt-country': myarray['country'],
                    'gt-state': myarray['nomos'],
                    'gt-city': myarray['city'],
                    'gt-zip': myarray['pc'],
                    'gt-phone': myarray['telephone'],
                    'gt-email': myarray['email'],
                    'gt-website': myarray['website'],
                    'gt-partner':  myarray['customer'],
                    'gt-textarea': "<img src='"+this.state.base64Data+"' />",
                });
                let config = {
                    method: 'post',
                    url: ''+ API_URL +'/gt/api/',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data : emailBody
                };
                axios(config)
                    .then(response => {
                            if(response.data.status === "success"){
                                // if(typeof response.data.invalidFields == "undefined"){
                                //     this.setState({successmsg: true});
                                //     this.setState({msg: response.data.message});
                                // }else if (response.data.invalidFields.length > 0 ) {
                                //     this.setState({ errormsg: true });
                                //     this.setState({ msg: response.data.message });
                                // }else {
                                //     this.setState({errormsg: true});
                                //     this.setState({msg: 'Υπάρχει κάποιο πρόβλημα κατα την αποστολή. Παρακαλώ προσπαθήστε αργότερα'});
                                // }
                            }
                        }
                    )
                    .catch(error => {
                        console.error('There was an error!', error);
                    });

            }
            if(response.data.error === 1){
                //console.log(1);
                this.setState({errormsg: true});
                this.setState({msg: response.data.message});
            }
            }
        ).catch(error => {
            console.error('There was an error!', error);
        });



    }


    render() {

        if(this.state.successmsg){
            return (<><Alert className='alert alert-success'  message={this.state.msg} /></>)
        }
        return (
            <>

                
                <Titles className='pb-3'>
                    <h2>Γίνε συνεργάτης μας και επωφελήσου</h2>
                    <p>
                        Η Γενική Ταχυδρομική αλλάζει τους κανόνες στις ηλεκτρονικές αγορές!
                    </p>
                </Titles>
                
                <div className="contact-box">
                    <div className="contact-box__container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="contact-box__content">
                                    <p>Προσθέστε τώρα το κατάστημά σας (ηλεκτρονικό ή φυσικό) στην <strong>πλατφόρμα GTeShops</strong> της Γενικής Ταχυδρομικής και <strong>προβληθείτε ΔΩΡΕΑΝ.</strong> </p>
                                    <p>Δείτε τις <strong>πωλήσεις σας να αυξάνονται</strong> μέσω της συνδυασμένης αποστολής* που προσφέρει η Γενική Ταχυδρομική στους χρήστες της υπηρεσίας.</p>
                                    <small>*Αγορές από πολλά καταστήματα, παράδοση σε 1 αποστολή, εξοικονόμηση μεταφορικών με ιδιαίτερα χαμηλή χρέωση μεταφορικών</small>
                                </div>
                            </div>
                            <div className="col-md-6">                                
                                <img className='img-fluid' src={contactImage} alt='Geniki Taxidromiki' />
                            </div>
                        </div>
                    </div>
                </div>
                
                <Container>
                    <div style={{maxWidth:'780px', margin:'auto', paddingBottom:'50px'}}>

                        <form onSubmit={this.sendData}>
                            <Card>
                                <div className="formtitle">Συμπλήρωσε την αίτηση με τα παρακάτω στοιχεία και το συντομότερο δυνατό θα επικοινωνήσει μαζί σου εκπρόσωπός μας.</div>

                                <MultiSelect options={this.state.categories} nochildren={this.state.nochildern} />

                                <Input name='nameSurname' label='Ονοματεπώνυμο' type='text' placeholder='Εισάγετε το ονοματεπώνυμό σας' required={true}/>
                                <Input name='business' label='Επωνυμία Επιχείρησης' type='text' placeholder='Εισάγετε την επωνυμία της επιχείρησης' required={true}/>
                                <Input name='business_name' label='Διακριτικός Τίτλος' type='text' placeholder='Εισάγετε τον διακριτικό τίτλο' required={true}/>
                                <Input name='profession' label='Δραστηριότητα' type='text' placeholder='Εισάγετε τη δραστηριότητα' required={true} />

                                <div className='row'>
                                    <div className='col-sm-12 col-xl-6'>
                                        <Input name='vat' label='ΑΦΜ' type='text' placeholder='Εισάγετε το ΑΦΜ' required={true}/>
                                    </div>
                                    <div className='col-sm-12 col-xl-6'>
                                        <label htmlFor='xora'>Χώρα</label>
                                        <select defaultValue={'Ελλάδα'} name='country' className='form-select' required>
                                            <option value='Ελλάδα' disabled>Ελλάδα</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-12 col-xl-6'>
                                        <Select label='Περιφέρεια' name={`nomos`} data={this.state.stateList} number={0}  ismore='0' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-6'>
                                        <Input name='city' label='Περιοχή' type='text' placeholder='Εισάγετε την περιοχή' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-8'>
                                        <Input name='address' label='Διεύθυνση' type='text' placeholder='Εισάγετε τη διεύθυνση' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-4'>
                                        <Input name='pc' label='TK' type='text' placeholder='Εισάγετε το ταχυδρομικό κώδικα' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-4'>
                                        <Input name='telephone' label='Τηλέφωνο' type='text' placeholder='Εισάγετε το τηλέφωνο' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-8'>
                                        <Input name='email' label='Email' type='email' placeholder='Εισάγετε το email' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-5'>
                                        <Input name='website' label='Ιστοσελίδα' type='text' placeholder='Εισάγετε τη διεύθυνση της ιστοσελίδας' required={true} />
                                    </div>
                                    <div className='col-sm-12 col-xl-7'>
                                        <Input name='customer' label='Πελάτης Γενικής Ταχυδρομικής' type='text' placeholder='Εισάγετε τοv κωδικό πελάτη της Γενικής Ταχυδρομικής' required={true} />
                                    </div>
                                </div>

                                <div className='col-sm-12 col-xl-12'>
                                    <label>Λογότυπο <span style={{fontSize:'12px'}}>(Ανεβάστε το λογότυπό σας σε διάσταση 200 x 200 pixels, να μην υπερβαίνει τα 100 kb και να είναι .jpg)</span></label>
                                    <input id="lgo_upload" accept='.jpg,.jpeg' className={`${classes.input}`} type="file" name="file" onChange={this.handleFileInputChange} required />
                                </div>

                            </Card>

                            {this.state.errormsg === true &&
                                <Alert className='alert alert-danger'  message={this.state.msg} />
                            }

                            <div className='float-end'>
                                <Button className='btn form-button' type='submit'>Αποστολή Αιτήματος</Button>
                            </div>
                        </form>
                    </div>
                </Container>
            </>
        )
    }
}

export default CooperationRequestForm;

