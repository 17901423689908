import React from 'react';
import axios from 'axios/index';

class GenikiStores extends React.Component {

    constructor(props) {
        super(props);
        this.state = { allStores:'', address1:'', city:'', email:'', name:'', phone:'', postcode:'',};
    }

    componentDidMount() {
        this.getAllStores();
    }

    componentDidUpdate (prevProps) {
        if (this.props.storeTitle !== prevProps.storeTitle) {
            this.getAllStores();
        }
    }


    getAllStores() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;

        axios.get(''+ API_URL +'/api/stores?'+ API_KEY +'&filter[name]=['+this.props.storeTitle+']&display=full&output_format=JSON').then(response => {
            if (typeof response.data.stores === 'undefined') {

                localStorage.removeItem('ByerFormShipping[genikiAddress]');
                localStorage.removeItem('ByerFormShipping[genikiCity]');
                localStorage.removeItem('ByerFormShipping[genikiEmail]');
                localStorage.removeItem('ByerFormShipping[genikiName]');
                localStorage.removeItem('ByerFormShipping[genikiPhone]');
                localStorage.removeItem('ByerFormShipping[genikiPostCode]');
                localStorage.removeItem('ByerFormShipping[genikiState]');

                this.setState({ allStores: [] });
            } else {

                localStorage.setItem('ByerFormShipping[genikiAddress]', response.data.stores[0]['address1'][1].value);
                localStorage.setItem('ByerFormShipping[genikiCity]', response.data.stores[0]['city']);
                localStorage.setItem('ByerFormShipping[genikiEmail]', response.data.stores[0]['email']);
                localStorage.setItem('ByerFormShipping[genikiName]', response.data.stores[0]['name'][1].value);
                localStorage.setItem('ByerFormShipping[genikiPhone]', response.data.stores[0]['phone']);
                localStorage.setItem('ByerFormShipping[genikiPostCode]', response.data.stores[0]['postcode']);
                localStorage.setItem('ByerFormShipping[genikiState]', response.data.stores[0]['id_state']);

                this.setState({allStores: response.data.stores,
                    address1: response.data.stores[0]['address1'][1].value,
                    city: response.data.stores[0]['city'],
                    email: response.data.stores[0]['email'],
                    name: response.data.stores[0]['name'][1].value,
                    phone: response.data.stores[0]['phone'],
                    postcode: response.data.stores[0]['postcode']
                });
            }
        });
    }

    render() {

        return (
            <>
                <div style={{fontSize:'13px'}} className="storeDetails">
                    <div className='table-payment-details-title'>Στοιχεία Παραλαβής</div>
                    <div className='table-payment-details-elements'>
                        <span className='grey-color-1'>Όνομα:</span>
                        <span className='blue-color-1'> {this.state.name}</span>
                    </div>

                    <div className='table-payment-details-elements'>
                        <span className='grey-color-1'>Διεύθυνση:</span>
                        <span className='blue-color-1'> {this.state.address1}</span>
                    </div>

                    <div className='table-payment-details-elements'>
                        <span className='grey-color-1'>TK:</span>
                        <span className='blue-color-1'> {this.state.postcode}</span>
                    </div>

                    <div className='table-payment-details-elements'>
                        <span className='grey-color-1'>Πόλη:</span>
                        <span className='blue-color-1'> {this.state.city}</span>
                    </div>

                    <div className='table-payment-details-elements'>
                        <span className='grey-color-1'>Τηλέφωνο:</span>
                        <span className='blue-color-1'> {this.state.phone}</span>
                    </div>
                </div>

            </>
        );
    }
}
export default GenikiStores;
