import React from 'react';
import classes from './Select.module.scss';
import ShowNewForm from '../../components/ShowNewForm';
import GenikiStores from "../../components/GetStoresDetails";

class Select extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showForm: [], moreOption:'', merchantData:''};

        this.showHideFormNewMerchant = e => {
            if (e.target.value === '-1') {
                this.setState({ showForm: [...this.state.showForm, <ShowNewForm number={this.props.number} />] });
            } else {
                this.setState({ showForm: [] });
            }
        };

        this.setInputHandler = e => {
            if (e.target.value === '-1') {
                localStorage.setItem(e.target.name, '-1');
            } else {
                localStorage.setItem(e.target.name, JSON.stringify(e.target.value));
            }
        };
    }

    setOnChangeStores (event) {

        if(this.props.name === "ByerFormShipping[collection-stores]"){
            let elem2=document.getElementsByClassName('storeDetails');
            Array.prototype.forEach.call(elem2, function (el) {
                el.style.display = 'block';
            });
            this.setState({ merchantData: <GenikiStores storeTitle={event.target.value} /> });
        }
    }

    componentDidMount() {
        if (typeof localStorage.getItem(this.props.name) !== 'undefined' && localStorage.getItem(this.props.name) !== null) {
            if (localStorage.getItem(this.props.name).replaceAll('"', '') === '-1') {
                this.setState({showForm: [...this.state.showForm, <ShowNewForm number={this.props.number}/>]});
            }
        }

        if(this.props.name === "ByerFormShipping[collection-stores]") {
            if (typeof localStorage.getItem('ByerFormShipping[collection-stores]') !== 'undefined' && localStorage.getItem('ByerFormShipping[collection-stores]') !== null) {
                this.setState({
                    merchantData: <GenikiStores
                        storeTitle={localStorage.getItem('ByerFormShipping[collection-stores]').replaceAll('"', '')}/>
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if(this.props.name === "ByerFormShipping[collection-stores]") {
                if (typeof localStorage.getItem('ByerFormShipping[collection-stores]') !== 'undefined' && localStorage.getItem('ByerFormShipping[collection-stores]') !== null) {
                    this.setState({
                        merchantData: <GenikiStores
                            storeTitle={localStorage.getItem('ByerFormShipping[collection-stores]').replaceAll('"', '')}/>
                    });
                }
            }
        }
    }


    render() {
        let isselected = '';
        isselected = localStorage.getItem(this.props.name);
        if (isselected == null) {
            isselected = '';
        }

        let dataToShow='';
        //console.log(this.props.ismore);
        return (
            <>
                <div className={classes['select-bottom-spacer-25']}>
                    <label htmlFor='disabledSelect' className={classes.label}>
                        {this.props.label}
                    </label>

                    <select defaultValue={''}
                            name={this.props.name}
                            className={`${classes.select} form-select`}
                            aria-label='select-shop'
                            onChange={e => {this.setOnChangeStores(e);this.showHideFormNewMerchant(e);this.setInputHandler(e);}}
                            required={ this.props.required ? true : false }
                    >
                        <option value="" disabled>Επιλέξτε</option>
                        {(() => {
                            if(this.props.ismore === '1') {
                                if(this.props.name !== "ByerFormShipping[collection-stores]") {
                                    if (isselected.replaceAll('"', '') === '-1') {
                                        return (<option value='-1' selected>Άλλο Κατάστημα</option>)
                                    } else {
                                        return (<option value='-1'>Άλλο Κατάστημα</option>)
                                    }
                                }
                            }
                        })()}

                        {this.props.data.map((item, index) => {
                            //dataToShow=item.name;
                            if(typeof item.name === 'object'){
                                dataToShow=item.name[1].value;
                            }else{
                                dataToShow=item.name;
                            }
                            if (isselected.replaceAll('"', '') === dataToShow) {
                                return (
                                    <option key={index} value={dataToShow} selected>
                                        {dataToShow}
                                    </option>
                                );
                            } else {
                                return <option  key={index} value={dataToShow}>{dataToShow} </option>;
                            }
                        })}
                    </select>
                </div>
                {this.state.merchantData}
                {this.state.showForm}
            </>
        );
    }
}

export default Select;
