import React, { Fragment } from 'react';
import Accordion from '../components/Accordion/Accordion';
import Titles from '../UI/Titles/Titles';
import Card from '../UI/Card/Card';

class Faq extends React.Component {
    render() {
        return (
            <Fragment>
                <Titles className='pb-3'>
                    <h2>Συχνές Ερωτήσεις</h2>
                    <p>
                        Έχεις απορίες για το πως λειτουργούν οι υπηρεσίες μας;
                        Παρακάτω θα βρεις τις απαντήσεις σε όλες σου τις ερωτήσεις.
                    </p>
                </Titles>
                <Card>
                    <Accordion />
                </Card>
            </Fragment>
        );
    }
}
export default Faq;

