import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const LeftMenuCategoriesData = createContext(null);

export const LeftMenuCategories = props => {
    const [mercCatData, setMerchCatData] = useState([]);

    const API_KEY = `${process.env.REACT_APP_KEY}`;
    const API_URL = `${process.env.REACT_APP_URL}`;
    const MERCHANTS_CATEGORIES_URL = `${API_URL}/api/merchantscategories?${API_KEY}&filter[active]=[1]&display=full&output_format=JSON`;

    useEffect(() => {
        axios
            .get(`${MERCHANTS_CATEGORIES_URL}`)
            .then(mercCat => {
                setMerchCatData(
                    mercCat.data.gteshops_merchants_categories_entitys
                );
            })
            .catch(err => {
                console.log(err);
            });
    }, [MERCHANTS_CATEGORIES_URL]);

    return (
        <LeftMenuCategoriesData.Provider value={{ mercCatData }}>
            {props.children}
        </LeftMenuCategoriesData.Provider>
    );
};
