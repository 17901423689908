import React, { Fragment } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Grid, Card } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import autoBind from 'auto-bind';
import classes from './MerchantCategories.module.scss';
import GetListingMerchants from './GetListingMerchants';
import Container from '../../UI/Container/Container';
import PageStyles from '../../UI/PageStyles/PageStyles';
import { withRouter } from 'react-router-dom';
import StoreCard from "./StoreCard";

class MerchantCategories extends React.Component {
    constructor(props) {
        super(props);

        let query = new URLSearchParams(this.props.location.search);
        let mcatId = query.get('catId');
        let mycatId = 0;
        if (mcatId > 0) {
            mycatId = mcatId;
        }
        this.state = {
            autoPlay: false,
            animation: 'fade',
            indicators: false,
            timeout: 500,
            navButtonsAlwaysVisible: true,
            navButtonsAlwaysInvisible: false,
            cycleNavigation: true,
            catId: mycatId,
            itemscat: [],
            newitemscat: [],
            subCategories: [],
            parrentID : null,
            activeIndex: null,
        };
        autoBind(this);
    }

    componentDidMount() {
        this.getCategories();
    }

    componentDidUpdate (prevProps) {

        if (prevProps.location.key !== this.props.location.key) {
            let query = new URLSearchParams(this.props.location.search);
            let mcatId = query.get('catId');
            let mycatId = 0;
            if (mcatId > 0) {
                mycatId = mcatId;
            }
            this.setState({ catId: mycatId });

        }

        if (prevProps.catId !== this.props.catId){
            this.getSubCategories();
        }

    }

    handleClick(id, parent_id) {

        this.setState(prevState => ({...prevState, catId: id === prevState.catId ? -1 : id,}));
        this.setState({ activeIndex: id });
        this.setState({ catId: id });
        this.setState({parrentID : parent_id})

    }


    getCategories() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;

        // axios.get(''+ API_URL +'/api/merchantscategories?'+ API_KEY +'&sort=[name_ASC]&filter[active]=[1]&display=full&output_format=JSON').then(pages => {
        axios.get(''+ API_URL +'/api/getParentChildCategories?'+ API_KEY +'&display=full&output_format=auto').then(pages => {


            if( typeof (pages.data.Categories) === 'undefined' ){
                this.setState({ itemscat: [] });
            }else{
                this.setState({ itemscat: pages.data.Categories });
            }

            //split array by 6
            let chunkSize = 0;
            let screenWidth= parseInt(window.screen.width);

            if(screenWidth <= 764) {
                chunkSize = 3;
            }else{
                chunkSize = 6;
            }

            let i=0;
            let p=0;
            let newArray=[];

            const groups = this.state.itemscat
                .map((e, i) => {
                    return i % chunkSize === 0 ? this.state.itemscat.slice(i, i + chunkSize) : null;
                })
                .filter(e => {
                    return e;
                });

            for(i=0; i< groups.length; i++){
                for(p=0; p < groups[i].length; p++) {
                    if(groups[i][p]['cat_id'] !== this.state.catId){
                        newArray.push(groups[i][p]);
                    }
                }
                for(p=0; p < groups[i].length; p++) {
                    if(groups[i][p]['cat_id'] === this.state.catId){
                        newArray.unshift(groups[i][p]);
                        this.setState({activeIndex: groups[i][p]['cat_id']});
                    }
                }
            }

            const groups2 = newArray
                .map((e, i) => {
                    return i % chunkSize === 0 ? newArray.slice(i, i + chunkSize) : null;
                })
                .filter(e => {
                    return e;
                });

            this.setState({ newitemscat: groups2 });
            
        });
    }


    render() {

        return (
            <Fragment>

                <Container>
                    <Carousel
                        navButtonsProps={{
                            style: {
                                backgroundColor: 'transparent',
                                borderRadius: 0,
                                color: '#285DAA',
                                margin: '0',
                                padding: '0',
                            },
                        }}
                        NextIcon={<ArrowForwardIosIcon />}
                        PrevIcon={<ArrowBackIosIcon />}
                        className={classes['merchant-categories']}
                        autoPlay={this.state.autoPlay}
                        animation={this.state.animation}
                        indicators={this.state.indicators}
                        timeout={this.state.timeout}
                        cycleNavigation={this.state.cycleNavigation}
                        navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                        navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                    >
                    
                        {this.state.newitemscat.map((itemCard, index) => ( 
                            <Card key={index} raised className='Banner'>
                                <Grid container spacing={0} className='BannerGrid'>
                                    {itemCard.map((item, index2) => {
                                        if ( item.parent_id === 0 )  {
                                            return(
                                                /*<Grid key={index2} item xs={12 / this.chunkSize}>*/
                                                <Grid key={index2} item xs={false}>
                                                    <div className={`${item.cat_id === this.state.activeIndex ? `${classes.active}` : ''} ${classes['carousel-item']} text-center`} style={{ cursor: 'pointer' }} onClick={() => this.handleClick(item.cat_id, null)}>
                                                        <div className='d-flex flex-column'>
                                                            <div className='image-holder mb-1'>
                                                                <img style={{ maxHeight: '47px', maxwidth: '100%' }} src={item.image} alt={item.cat_name} />
                                                            </div>
                                                            {item.cat_name}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        }
                                        return null;
                                        }
                                    )}
                                </Grid>
                            </Card>
                        ))}
                    </Carousel>
                </Container>
                <Container>
                    <div className={classes['subcategories-box']}>
                    {this.state.itemscat.length === 0 ? (
                        <>
                        </>
                    ) : (

                        this.state.itemscat.map((item, index) => {
                            if( item.parent_id === Number(this.state.catId) || item.parent_id === Number(this.state.parrentID)  ) {
                                if( item.parent_id !== 0 ){
                                    return (
                                        <div className={`${item.cat_id === this.state.activeIndex ? `${classes['subcategories-box__item--active']}` : ''} ${classes['subcategories-box__item']}`} key={index} style={{ cursor: 'pointer' }} onClick={() => this.handleClick(item.cat_id, item.parent_id)}>
                                            {item.cat_name}
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })
                    )}
                    </div>
                </Container>
                <PageStyles className='py-4'>
                    <GetListingMerchants catId={Number(this.state.catId)} />
                </PageStyles>
            </Fragment>
        );
    }
}
//export default MerchantCategories;
export default withRouter(MerchantCategories);
