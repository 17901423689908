import { Fragment} from 'react';
import classes from './LeftMenu.module.scss';
import { Link } from 'react-router-dom';

function LeftMenu() {

    return (
        <Fragment>
            <div className={`${classes['menu-title']}`}>Εντολή Αγοράς</div>
            <div className={classes['vertical-list']}>
                <div className='row'>

                    <div className='col-6 col-md-12'>
                        <div className={classes['category-item']}>
                            <Link to={`/entoli-agoras?type=1`}>
                                <div className='d-block d-md-none'>
                                    <div className='img-fluid'></div>
                                </div>
                                Από συνεργαζόμενα <br/>eshops / καταστήματα
                            </Link>
                        </div>
                    </div>

                    <div className='col-6 col-md-12'>
                        <div className={classes['category-item']}>
                            <Link to={`/entoli-agoras?type=2`}>
                                <div className='d-block d-md-none'>
                                    <div className='img-fluid'></div>
                                </div>
                                Από οποιoδήποτε <br/>eshop / κατάστημα
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes['menu-title']}`} style={{marginTop: '20px'}}>Εντολή Παραλαβής</div>
            <div className={classes['vertical-list']}>
                <div className='row'>

                    <div className='col-6 col-md-12'>
                        <div className={classes['category-item']}>
                            <Link to={`/entoli-paralavis?type=1`}>
                                <div className='d-block d-md-none'>
                                    <div className='img-fluid'></div>
                                </div>
                                Από συνεργαζόμενα <br/>eshops / καταστήματα
                            </Link>
                        </div>
                    </div>

                    <div className='col-6 col-md-12'>
                        <div className={classes['category-item']}>
                            <Link to={`/entoli-paralavis?type=2`}>
                                <div className='d-block d-md-none'>
                                    <div className='img-fluid'></div>
                                </div>
                                Από οποιoδήποτε <br/>eshop / κατάστημα
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LeftMenu;
