import React from 'react';
import classes from './Input.module.scss';

class Input extends React.Component {

    constructor(props) {
        super(props);
        this.state = { text: this.props.value, thename: this.props.name, ischecked : this.props.checked};

        this.setInputHandler = e => {
            this.setState({ ischecked: '' });
            this.setState({ text: e.target.value });
            localStorage.setItem(e.target.name, JSON.stringify(e.target.value));
        };

    }

    componentWillReceiveProps(prevProps) {
    	if(prevProps.name !== this.props.name){
			this.setState({text: localStorage.getItem(prevProps.name).replaceAll('"', '')});
        }
    }

    render() {
		let showvalue="";
        if (typeof this.state.text !== 'undefined' && this.state.text !== null){
			showvalue=this.state.text.replaceAll('"', '');
        }
        let cssSle='';

        if(this.props.type === 'radio'){
            cssSle='';
        }else{
            cssSle='form-control';
        }
        return (
			<div>
                <div className={classes['input-bottom-spacer-25']}>
                    <div className='form-group'>
                        <label className={classes.label} htmlFor={this.props.id}>{this.props.label}</label>

                        {(() => {

                            if (typeof this.props.checked !== 'undefined' && this.props.checked !== null) {

                                    if(this.state.ischecked === 'checked') {
                                        return (
                                            <input checked onChange={(e) => this.setInputHandler(e)}
                                                   className={`${classes.input} ${cssSle}`} type={this.props.type}
                                                   id={this.props.id} placeholder={this.props.placeholder}
                                                   key={this.props.name}
                                                   value={showvalue}
                                                   name={this.props.name}
                                                   required={ this.props.required ? true : false }
                                            />
                                        )
                                    }else{
                                        return (
                                            <input onChange={(e) => this.setInputHandler(e)}
                                                   className={`${classes.input} ${cssSle}`} type={this.props.type}
                                                   id={this.props.id} placeholder={this.props.placeholder}
                                                   key={this.props.name}
                                                   value={showvalue}
                                                   name={this.props.name}
                                                   required={ this.props.required ? true : false }
                                            />
                                        )
                                    }

                            }else{

                                return (
                                    <input onChange={(e) => this.setInputHandler(e)} className={`${classes.input} ${cssSle}` } type={this.props.type} id={this.props.id} placeholder={this.props.placeholder} key={this.props.name}
                                           value={showvalue}
                                           name={this.props.name}
                                           maxLength={this.props.maxlength}
                                           minLength={this.props.minlength}
                                           required={ this.props.required ? true : false }
                                    />
                                )
                            }
                        })()}


                    </div>
                </div>
			</div>
        );

    }
}

export default Input;



