import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import CardMedia from '@mui/material/CardMedia';
import axios from 'axios';
import classes from './Slideshow.module.scss';

function Slideshow(props) {
    const [slideshow, setSlideshow] = useState([]);
    useEffect(() => {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios
            .get(''+ API_URL +'/api/slider?'+ API_KEY +'&filter[active]=[1]&display=full&output_format=JSON')
            .then(mercCat => {
                setSlideshow(mercCat.data.gteshops_slider_entitys);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    if (typeof slideshow !== 'undefined' && slideshow !== null) {
        return (
            <Carousel navButtonsAlwaysInvisible={true} interval={7000} animation='fade'
                      reverseEdgeAnimationDirection={true} indicators={false}>
                {slideshow.map(item => (
                    <Item key={item.id} name={item.name} imageUrl={item.image} content={item.content}
                          youtube={item.youtube}/>
                ))}
            </Carousel>
        );
    }else{
        return (<></>)
    }
}

function Item(props) {
    return (
        <div className={classes.slideshow}>
            {props.youtube != null ? (
                <CardMedia component='iframe' src={`https://iframe.videodelivery.net/${props.youtube}&preload=true&loop=true&autoplay=true&controls=false`} />
            ) : (
                <Paper key={props.id}>
                    <div className={classes['position-elements-left']}>
                        <h2>{props.name}</h2>
                        <p>{props.content}</p>
                    </div>
                    <div className={classes['relative-position']}>
                        <div className={classes['img-overlay']}>
                            <img className={classes['img-fluid']} alt={props.name} src={props.imageUrl} />
                        </div>
                    </div>
                </Paper>
            )}
        </div>
    );
}

export default Slideshow;
