import { ReactComponent as CommentIcon } from '../../assets/images/svg/comment-icon.svg';
import ReactTooltip from 'react-tooltip';
import GetStoresDetails from '../../components/GetStoresDetails';
import React, {useEffect} from 'react';

function StepThreeForm() {

    let myarr = [0];
    let orderComment='';

    if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
        if (localStorage.getItem('ByerFormCount').includes('-')) {
            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
            myarr = allReadyKeys.split('-');
        }
    }

    const setOnChangeShipping = event => {
        localStorage.setItem('ByerFormShipping[selectShipping]', event.target.value);
    };

    useEffect(() => {
        if (typeof localStorage.getItem('ByerFormShipping[selectShipping]') === 'undefined' || localStorage.getItem('ByerFormShipping[selectShipping]') === null) {

            if (typeof document.getElementById('selectShipping1') !== 'undefined' && document.getElementById('selectShipping1') !== null) {
                let elemcl = document.getElementById('selectShipping1');
                elemcl.checked = true;
            }
            localStorage.setItem('ByerFormShipping[selectShipping]', 'Προπληρωμή μεταφορικών');
        }else{
            if(localStorage.getItem('ByerFormShipping[selectShipping]') === 'Προπληρωμή μεταφορικών'){
                let elemcl=document.getElementById('selectShipping1');
                elemcl.checked=true;
            }else if(localStorage.getItem('ByerFormShipping[selectShipping]') === 'Πληρωμή Μεταφορικών στην Παράδοση'){
                let elemcl=document.getElementById('selectShipping2');
                elemcl.checked=true;
            }
        }
    }, []);


    if (typeof localStorage.getItem('ByerFormShipping[message]') !== 'undefined' && localStorage.getItem('ByerFormShipping[message]') !== null) {
        orderComment=localStorage.getItem('ByerFormShipping[message]').replaceAll('"', '');
    }
    if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {

        return (

            <div className='stepper-table'>
                <div className='d-none d-xl-block'>
                    <div className='table-grid'>
                        <div className='table-title'>
                            <div>Α/Α</div>
                            <div>Κατάστημα</div>
                            <div>Αρ. Παραγγελίας</div>
                            <div>Σχόλιο</div>
                        </div>

                        {(() => {
                            let container = [];
                            let z = 0;
                            let titleMerchant = '';
                            let productComment = '';

                            Object.keys(myarr).map(function (p) {
                                z++;
                                if (typeof localStorage.getItem('form[' + p + '][merchant]') !== 'undefined' && localStorage.getItem('form[' + p + '][merchant]') !== null) {
                                    if (localStorage.getItem('form[' + p + '][merchant]') === "-1") {
                                        titleMerchant = localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                    } else {
                                        titleMerchant = localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '');
                                    }
                                }
                                if (typeof localStorage.getItem('form[' + p + '][order-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][order-comment]') !== null) {
                                    productComment = localStorage.getItem('form[' + p + '][order-comment]').replaceAll('"', '');
                                }
                                return (
                                    container.push(
                                        <div className='table-body'>
                                            <div className='item'>{z}</div>
                                            <div className='item'>{titleMerchant}</div>
                                            <div
                                                className='item'>{localStorage.getItem('form[' + p + '][order_number]').replaceAll('"', '')}</div>
                                            <div className='item'>
                                                <CommentIcon data-tip={productComment} data-place='left'
                                                             data-background-color='#2599FC' data-text-color='#fff'
                                                             data-effect='solid' data-multiline={true}
                                                             alt='information button'/>
                                                <ReactTooltip/>
                                            </div>
                                        </div>
                                    )
                                )
                            })
                            return container;
                        })()}
                    </div>
                </div>

                <div className='step-3-mobile-view d-block d-xl-none'>

                    {(() => {
                        let container = [];
                        let z = 0;
                        let titleMerchant = '';
                        let productComment = '';

                        Object.keys(myarr).map(function (p) {
                            z++;

                            if (typeof localStorage.getItem('form[' + p + '][merchant]') !== 'undefined' && localStorage.getItem('form[' + p + '][merchant]') !== null) {
                                if (localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '') === "-1") {
                                    titleMerchant = localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                } else {
                                    titleMerchant = localStorage.getItem('form[' + p + '][merchant]').replaceAll('"', '');
                                }
                            }

                            if (typeof localStorage.getItem('form[' + p + '][order-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][order-comment]') !== null) {
                                productComment = localStorage.getItem('form[' + p + '][order-comment]').replaceAll('"', '');
                            }
                            return (
                                container.push(
                                    <div>
                                        <div
                                            className='product-title'>{z}. {localStorage.getItem('form[' + p + '][order_number]').replaceAll('"', '')}</div>
                                        <div className='card-body'>
                                            <div className='card-container'>
                                                <div className='card-title'>A/A</div>
                                                <div className='card-detail'>{z}</div>
                                            </div>

                                            <div className='card-container'>
                                                <div className='card-title'>Κατάστημα</div>
                                                <div className='card-detail'>{titleMerchant}</div>
                                            </div>

                                            <div className='card-container'>
                                                <div className='card-title'>Αρ. Παραγγελίας</div>
                                                <div
                                                    className='card-detail'>{localStorage.getItem('form[' + p + '][order_number]').replaceAll('"', '')}</div>
                                            </div>

                                            <div className='card-container'>
                                                <div className='card-title'>Σχόλιο</div>
                                                <div className='card-detail'>{productComment}</div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            )
                        })
                        return container;
                    })()}

                </div>


                <div className='table-payment-deitails-container'>
                    <div className='table-payment-details'>

                        {(() => {
                            if (typeof localStorage.getItem('ByerFormShipping[collection-stores]') !== 'undefined' && localStorage.getItem('ByerFormShipping[collection-stores]') !== null) {
                                return (
                                    <GetStoresDetails
                                        storeTitle={localStorage.getItem('ByerFormShipping[collection-stores]').replaceAll('"', '')}/>
                                )
                            } else {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Παραλαβής</div>

                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                {localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιοχή:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[email]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }
                        })()}

                        {(() => {
                            if (typeof localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== 'undefined' && localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== null) {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                  {localStorage.getItem('ByerFormShipping[receiptFirst-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[receiptLast-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptAddress]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptArea]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Πόλη:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptTown]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptZip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptEmail]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptTelephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            } else if (typeof localStorage.getItem('ByerFormShipping[businessName]') !== 'undefined' && localStorage.getItem('ByerFormShipping[businessName]') !== null) {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Επωνυμία:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessName]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Δραστηριότητα:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessProfession]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΑΦΜ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessAfm]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΔΟΥ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessDoy]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessAddress]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Πόλη:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessTown]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessZip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessEmail]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessTelephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                {localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιοχή:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[email]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span
                                                className='blue-color-1'>{localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        <div className='table-payment-details-holder'>
                            <div className='table-payment-details-title'>Σχόλια Παραγγελίας</div>
                            <div className='table-payment-details-comments'>{orderComment}</div>
                        </div>
                    </div>
                </div>

                <div className='table-payment-deitails-container' style={{paddingTop: '50px', marginTop: '0px'}}>
                    <div className='row'>
                        <div className='col-sm-12 col-xl-3 table-payment-details-title'
                             style={{fontSize: '1.125rem', color: '#285daa'}}>Πληρωμή Μεταφορικών:
                        </div>
                        <div className='col-sm-12 col-xl-4 grey-color-1'>
                            <label htmlFor='selectShipping1'>Προπληρωμή μεταφορικών &nbsp;</label>
                            <input onChange={setOnChangeShipping} type='radio' id='selectShipping1'
                                   name='ByerFormShipping[selectShipping]' value='Προπληρωμή μεταφορικών'/>
                            <div style={{fontSize: '12px'}}>Το κόστος των μεταφορικών θα αξιολογηθεί και θα
                                συμπεριληφθεί στο link πληρωμής που θα λάβετε
                            </div>
                        </div>

                        <div className='col-sm-12 col-xl-5 grey-color-1'>
                            <label htmlFor='selectShipping2'>Πληρωμή μεταφορικών στην παράδοση &nbsp;</label>
                            <input onChange={setOnChangeShipping} type='radio' id='selectShipping2'
                                   name='ByerFormShipping[selectShipping]' value='Πληρωμή Μεταφορικών στην Παράδοση'/>
                            <div style={{fontSize: '12px'}}>Το κόστος μεταφορικών θα αξιολογηθεί, θα σας γνωστοποιηθεί,
                                ΔΕΝ θα περιληφθεί στο link πληρωμής και θα εξοφληθεί κατά την παράδοση
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        )
    }else{
        return (<></>)
    }
}

export default StepThreeForm;
