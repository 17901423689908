import React from 'react';
import ReceiptForm from './ReceiptForm';
import Container from '../UI/Container/Container';
import Titles from '../UI/Titles/Titles';
import ContainerDivider from '../UI/ContainerDivider/ContainerDivider';
import { ReactComponent as AddFormIcon } from '../assets/images/svg/add-form-icon.svg';


class Step1 extends React.Component {

    constructor(props) {
        super(props);
        //localStorage.clear();
        this.removeRow = this.removeRow.bind(this);
        this.onAddOrderChild = this.onAddOrderChild.bind(this);

        if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
            let myarr = allReadyKeys.split('-');
            let pa = [];
            for (let i = 0, l = myarr.length; i < l; i++) {
                pa.push(<ReceiptForm number={myarr[i]} handler={this.removeRow} flag={i} key={i} />);
            }
            this.state = { formKeys: pa };
        } else {
            this.state = { formKeys: [<ReceiptForm number='0' handler={this.removeRow} flag={0} key={0} />] };
            localStorage.setItem('ByerFormCount', 'null');
        }
    }

    onAddOrderChild(e) {
        e.preventDefault();
        this.setState({ formKeys: [...this.state.formKeys, <ReceiptForm number={this.state.formKeys.length} handler={this.removeRow} flag={this.state.formKeys.length} />] });
        let allReadyKeys = localStorage.getItem('ByerFormCount') + '-' + this.state.formKeys.length;
        localStorage.setItem('ByerFormCount', allReadyKeys);
    }

    removeRow(val) {
        let els = document.getElementsByClassName(val + 'mid');
        Array.prototype.forEach.call(els, function (el) {
            el.remove();
            localStorage.removeItem('form['+val+'][merchant]');
            localStorage.removeItem('form['+val+'][order-comment]');
            localStorage.removeItem('form['+val+'][order_number]');

            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('-' + val, '');
            localStorage.setItem('ByerFormCount', allReadyKeys);
        });

        let elsT = document.getElementsByClassName('DtaTitle');
        let p = 0;
        Array.prototype.forEach.call(elsT, function (elp) {
            p++;
            elp.innerHTML = p + 'η';
        });
    }


    render() {
        return (
            <>
                <Container className='px-2 px-xl-17'>
                    <ContainerDivider className='pt-4 pb-3'>
                        <Titles>
                            <h2>Διαδικασία Καταχώρισης Εντολής Παραλαβής</h2>
                            <p>Καταχωρίστε τις παραγγελίες που έχετε ήδη ολοκληρώσει σε ηλεκτρονικά καταστήματα και επιθυμείτε να παραλάβει η Γενική Ταχυδρομική. Η παράδοση των προϊόντων θα δρομολογηθεί ως μία αποστολή.</p>
                        </Titles>
                    </ContainerDivider>

                    {this.state.formKeys}

                    <ContainerDivider className='py-2'>
                        <div className='add-product-button text-center'>
                            <button onClick={this.onAddOrderChild}>
                                Προσθήκη εντολής <AddFormIcon />
                            </button>
                        </div>
                    </ContainerDivider>
                </Container>
            </>
        );
    }
}
export default Step1;
