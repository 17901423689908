import React from 'react';
import Card from '../UI/Card/Card';
import Input from '../UI/Input/Input';
import Select from '../UI/Select/Select';
import Collapse from '../UI/Collapse/Collapse';
import axios from 'axios/index';
import DeleteIcon from '../assets/images/svg/delete-icon.png';
import ReactTooltip from 'react-tooltip';
import tooltipicon from '../assets/images/svg/tooltip-icon.png';
import classes from '../UI/Input/Input.module.scss';


import TextArea from '../UI/TextArea/TextArea';

class ByerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { merch: [] };
    }

    componentDidMount() {
        this.getAllMerchants();
    }

    getAllMerchants() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios.get(''+ API_URL +'/api/merchants?'+ API_KEY +'&filter[active]=[1]%&sort=[name_ASC]&display=full&output_format=JSON').then(response => {
            if (typeof response.data.gteshops_merchants_entitys === 'undefined') {
                this.setState({ merch: [] });
            } else {
                this.setState({
                    merch: response.data.gteshops_merchants_entitys,
                });
            }
        });
    }

    render() {

        let formNumbern = this.props.flag;
        formNumbern++;
        const formNumber = formNumbern + 'o';
        const classNameM = this.props.number + 'mid';


        let elsT = document.getElementsByClassName('DtaTitle');
        let p = 0;
        Array.prototype.forEach.call(elsT, function (elp) {
            p++;
            elp.innerHTML = p + 'ο';
        });

        return (
            <>
                <div className={classNameM}>
                    <Card number={formNumber} title='Προϊόν' rel={this.props.number}>

                        <div className='row'>
                            <Input type='text' name={`form[${this.props.number}][product-name]`} id='product-name' label='Μοντέλο / Τίτλος Προϊόντος' placeholder='Αναφέρατε το όνομα του προϊόντος' value={localStorage.getItem(`form[${this.props.number}][product-name]`)}  maxlength="250" required={true} />
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-11'>
                                <Select label='Κατάστημα Αγοράς' name={`form[${this.props.number}][product-shop]`} data={this.state.merch} number={this.props.number}  ismore='1' required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-1'>
                                <img
                                    width='30'
                                    className={`${classes.tooltip}`}
                                    src={tooltipicon}
                                    data-tip='Eπιλέξτε ένα από τα συνεργαζόμενα καταστήματα της λίστας.<br/>Εάν το κατάστημα ενδιαφέροντος σας δεν περιλαμβάνεται στην λίστα<br/> επιλέξτε "Άλλο Κατάστημα" και συμπληρώστε τα στοιχεία του.'
                                    data-place='right'
                                    data-background-color='#2599FC'
                                    data-text-color='#fff'
                                    data-effect='solid'
                                    data-multiline={true}
                                    alt='information button'
                                />
                                <ReactTooltip />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-11'>
                                <Input type='text' label='Link Προϊόντος' placeholder='Link Προϊόντος' name={`form[${this.props.number}][product-link]`} value={localStorage.getItem(`form[${this.props.number}][product-link]`)} required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-1'>
                                <img
                                    width='30'
                                    className={`${classes.tooltip}`}
                                    src={tooltipicon}
                                    data-tip='Συμπληρώστε τον σύνδεσμο (link) του eShop από όπου θέλετε να γίνει η αγορά.<br/>Σε περίπτωση που το κατάστημα ενδιαφέροντος σας δεν έχει ηλεκτρονικό κατάστημα, αφήστε το πεδίο κενό.'
                                    data-place='right'
                                    data-background-color='#2599FC'
                                    data-text-color='#fff'
                                    data-effect='solid'
                                    data-multiline={true}
                                    alt='information button'
                                />
                                <ReactTooltip />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-xl-2'>
                                <Input type='number' label='TMX' placeholder='' name={`form[${this.props.number}][product-quantity]`} value={localStorage.getItem(`form[${this.props.number}][product-quantity]`)} required={true} />
                            </div>
                            <div className='col-sm-12 col-xl-10'>
                                <Input placeholder='Τα δεκαδικά με τελεία.' type='number' label='Αξία με ΦΠΑ (Τιμή μονάδος)' name={`form[${this.props.number}][product-price]`} value={localStorage.getItem(`form[${this.props.number}][product-price]`)} required={true}/>
                            </div>
                        </div>

                        <Collapse title='Σχόλιο για το προϊόν'>
                            <TextArea className='form-control' placeholder='Εισάγετε σχόλια που αφορούν την αγορά του προϊόντος.' rows='4' name={`form[${this.props.number}][product-comment]`} value={localStorage.getItem(`form[${this.props.number}][product-comment]`)} required={false}></TextArea>
                        </Collapse>

                        {(() => {
                            if(formNumbern !== 1){
                                return (
                                    <div className='text-center' onClick={() => this.props.handler(this.props.number)}>
                                        <img src={DeleteIcon} style={{ width: '21px', cursor: 'pointer' }} alt='bin icon' />
                                    </div>
                                )
                            }
                        })()}
                    </Card>
                </div>
            </>
        );
    }
}
export default ByerForm;
