import PageStyles from '../UI/PageStyles/PageStyles';
import ReceiptStepper from '../components/ReceiptStepper';


function ReceiptOrder() {
    return (
        <PageStyles className='pb-3 mt-12 mt-lg-0'>
            <ReceiptStepper />
        </PageStyles>
    );
}

export default ReceiptOrder;
