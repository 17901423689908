import { Fragment, useState, useRef } from 'react';
import classes from './Collapse.module.scss';
import { ReactComponent as CommentIcon } from '../../assets/images/svg/comment-icon.svg';

function Collapse(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [panelHeight, setPanelHeight] = useState();
    const panelHeightRef = useRef();

    const currentHeight = isExpanded ? panelHeight : 0;

    const isExpandedHandler = () => {
        setIsExpanded(!isExpanded);
        setPanelHeight(panelHeightRef.current.clientHeight);
    };

    return (
        <Fragment>
            <div className={`${classes['panel']} ${isExpanded ? `${classes['is-expanded']} ` : ''}`}>
                <div onClick={isExpandedHandler} className={classes['panel-heading']}>
                    <CommentIcon /> {props.title}
                </div>

                <div className={classes['panel-collapse']} style={{ height: `${currentHeight}px` }}>
                    <div className={classes['panel-body']} ref={panelHeightRef}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Collapse;
