import classes from './TextArea.module.scss';
import { useLocalStorage } from '../../components/useLocalStorage';

function TextArea(props) {
    const [state, setInputHandler] = useLocalStorage(props.name, '');

	return (
		<div className='form-group'>
			{props.label && (<label className={classes.label} htmlFor={props.id}>{props.label}</label>)}
			<textarea className='form-control'
					  id={props.id}
					  placeholder={props.placeholder}
					  rows={props.rows}
					  name={props.name}
					  onChange={(e) => setInputHandler(e.target.value)}
					  value={state}
					  required={ props.required ? true : false }
			/>
		</div>
	);
}

export default TextArea;
