import React, { useContext } from 'react';
import classes from './TopNavigation.module.scss';
import Logo2 from '../../assets/images/geniki-taxidromiki.png';
import { Link } from 'react-router-dom';
import Container from '../../UI/Container/Container';
import { MenuCategoriesData } from '../../context/MenuCategories';
import AutoCompleteInput from '../AutoComplete';
import ReactTooltip from 'react-tooltip';
import tooltipicon from '../../assets/images/svg/tooltip-icon.png';
import { useLocation } from 'react-router-dom';

function TopNavigation(props) {
    const MenuCategories = useContext(MenuCategoriesData);
    const location = useLocation();
    let flag='';
    return (
        <nav className={`${classes['top-navigation']} ${classes.sticky}`}>
            <div className={`${classes['top-ribbon']}`}>Κάντε συνδυασμένες παραγγελίες γλιτώστε μεταφορικά!</div>
            <Container>
                <div className={`${classes['top-navigation-holder']}`}>
                    <div className={classes['logo-search-holder']}>
                        <div className='logo me-md-8 '>
                            <Link to='/'>
                                <img className='img-fluid' width='100px' alt='GtEshops.com' src={Logo2} />
                            </Link>
                        </div>
                        <div className={classes['search']}>
                            <form>
                                <AutoCompleteInput />
                            </form>
                        </div>
                    </div>
                    <div className={`${classes['menu-links']} mx-sm-auto me-lg-0 ms-lg-auto`}>
                        <ul>                       
                            {MenuCategories.menuCategories.map(menuCategory => {
                                if(menuCategory.id === 8){
                                    flag='Η υπηρεσία αφορά την εκ μέρους σας εξ ολοκλήρου ανάθεση αγοράς προϊόντων<br/> για λογαριασμό σας στη Γενική Ταχυδρομική  και την παράδοσή της σε εσάς.';
                                }else{
                                    flag='Η υπηρεσία αφορά την παραλαβή (ή και) ομαδοποίηση παραγγελιών<br/> που ήδη έχουν καταχωρισθεί σε ηλεκτρονικά καταστήματα';
                                }
                                return menuCategory.id_cms_category === '3' && menuCategory.active === '1' ? (
                                    <li key={menuCategory.id}>
                                        <img
                                            src={tooltipicon}
                                            data-tip={flag}
                                            data-place='left'
                                            data-background-color='#2599FC'
                                            data-text-color='#fff'
                                            data-effect='solid'
                                            data-multiline={true}
                                            alt='information button'
                                        />
                                        <ReactTooltip />

                                        {(() => {
                                            if(location.pathname === '/'+menuCategory.link_rewrite[1].value){
                                                return (
                                                    <a href='#'>{menuCategory.meta_title[1].value}</a>
                                                )
                                            }else{
                                                return (
                                                    <Link onClick={() => window.localStorage.clear()} to={menuCategory.link_rewrite[1].value}>{menuCategory.meta_title[1].value}</Link>
                                                )
                                            }
                                        })()}
                                    </li>
                                ) : null;
                            })}
                        </ul>
                    </div>
                </div>
            </Container>
        </nav>
    );
}

export default TopNavigation;
