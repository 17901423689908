import HomeSection2 from '../components/HomeSection2/HomeSection2';
import LeftMenu from '../components/LeftMenu/LeftMenu';
import Slideshow from '../components/Slideshow/Slideshow';
import { LeftMenuCategories } from '../context/LeftMenuCategories';

function Home(props) {

    return (
        <div>
            <div style={{paddingTop:'20px'}} className='container px-2 px-md-1 mb-4'>
                <div className='row'>
                    <div className='col-sm-12 col-xl-2 order-2 order-xl-1'>
                        <LeftMenuCategories>
                            <LeftMenu />
                        </LeftMenuCategories>
                    </div>
                    <div className='col-sm-12 col-xl-10 order-1 order-xl-2 mt-5 mt-xl-0 mb-2 mb-xl-0'>
                        <Slideshow />
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <HomeSection2 />
                </div>
            </div>
        </div>
    );
}

export default Home;
