import classes from './Titles.module.scss';

function Titles(props) {
	return (
		<div className={`${classes['page-title']} ${props.className}`}>
			{props.children}
		</div>
	);
}

export default Titles;
