import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuCategoriesData } from '../../context/MenuCategories';


function ItemsList(props) {
    const MenuCategories = useContext(MenuCategoriesData);

    return (
        <ul className={props.className}>
            {MenuCategories.menuCategories.map(category => {
                return category.id_cms_category === props.cmsCategory &&
                    category.active === '1' ? (
                    <li key={category.id}>
                        <Link
                            to={`/${category.link_rewrite[1].value}`}
                        >
                            {category.meta_title[1].value}
                        </Link>
                    </li>
                ) : null;
            })}
        </ul>
    );
}

export default ItemsList;
