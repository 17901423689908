import classes from './Accordion.module.scss';
import React from 'react';
import axios from 'axios/index';
import ReactHtmlParser from 'react-html-parser';

class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allData: [],
            isExpanded: false,
            panelHeight: '',
            reorderAllData: '',
            allclasses: classes,
            allDataNew: [],
            heightArray: 0,
        };
    }

    componentDidMount() {
        this.getAllData();
    }

    isExpandedHandler(e) {
        var els = document.getElementsByClassName('MyExp');
        Array.prototype.forEach.call(els, function (el) {
            el.setAttribute('style', 'height:0px');
        });

        document.getElementById(e.target.dataset.id).setAttribute('style', `height: auto`);
    }

    getAllData() {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios
            .get(''+ API_URL +'/api/content_management_system?'+ API_KEY +'&filter[id_cms_category]=7&display=full&output_format=JSON')
            .then(response => {
                if (typeof response.data.content_management_system === 'undefined') {
                    this.setState({ allData: [] });
                } else {
                    this.setState({
                        allData: response.data.content_management_system,
                    });

                    let MyArr = [];
                    let MyArrDa = [];

                    this.state.allData.map((item, index) => {
                        MyArrDa[index] = {
                            title: item.meta_title[1].value,
                            content: item.content[1].value,
                            parent: item.meta_keywords[1].value,
                        };
                        MyArr[item.meta_keywords[1].value] = MyArrDa;
                        return MyArrDa;
                    });
                    this.setState({ allDataNew: MyArr });
                }
            });
    }

    render() {
        let myarray = this.state.allDataNew;
        let myclasses = this.state.allclasses;
        let isExpandedHandler = this.isExpandedHandler;
        let Nheight = this.state.heightArray;

        return (
            <div className={`${classes['accordion']}`}>
                {Object.keys(myarray).map(function (key, index2) {
                    if (key !== 0) {
                        return (
                            <div key={index2}>
                                <div className={myclasses['accordion-category-title']}>
                                    <h2>{key}</h2>
                                </div>
                                {myarray[key].map((item, index) => {
                                    if (key === item.parent) {
                                        return (
                                            <div key={index} style={{paddingBottom:'10px'}}>
                                                <div onClick={isExpandedHandler} className={myclasses['accordion-title']}>
                                                    <h1 data-id={index}>{item.title}</h1>
                                                </div>
                                                <div
                                                    id={index}
                                                    className={`${myclasses['accordion-collapse']} MyExp`}
                                                    style={{
                                                        height: `${Nheight}`,
                                                    }}
                                                >
                                                    <div className={myclasses['accordion-body']}>
                                                        <div style={{padding:'10px'}}>{ReactHtmlParser(item.content)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }
}


export default Collapse;
