import React, {useState, Fragment, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Stepper, Step, StepLabel } from '@material-ui/core';

import Container from '../UI/Container/Container';
import Titles from '../UI/Titles/Titles';
import ContainerDivider from '../UI/ContainerDivider/ContainerDivider';
import StepTwoForm from '../components/ReceiptStepper/StepTwoForm';
import ProcessOrder from './ProcessOrder';
import Step1 from './Step1';

import GetStoresDetails from '../components/GetStoresDetails';

import { ReactComponent as CommentIcon } from '../assets/images/svg/comment-icon.svg';

const useStyles = makeStyles(() => ({
    button: {
        borderRadius: '27px',
        fontSize: '16px',
        textTransform: 'none',
        padding: '15px 40px',
        fontFamily: 'CF Asty Std Bold',
        lineHeight: '1rem',
    },
}));

function getSteps() {
    return [`Βήμα 1ο Συμπλήρωση Αίτησης`, 'Βήμα 2ο Στοιχεία Πελάτη', 'Βήμα 3ο Καταχώριση Εντολής Αγοράς'];
}

const Step2 = () => {
    return (
        <Container className='px-xl-17'>
            <ContainerDivider className='pt-4 pb-3'>
                <Titles>
                    <h2>Στοιχεία Πελάτη</h2>
                    <p>Συμπλήρωστε τα στοιχεία σας και επιλέξτε τύπο παραστατικού και τρόπο αποστολής.</p>
                </Titles>
            </ContainerDivider>
            <StepTwoForm />
        </Container>
    );
};

const Step3 = () => {

    let myarr = [0];
    let totalprice = 0;
    let orderComment='';

    const setOnChangeShipping = event => {
        localStorage.setItem('ByerFormShipping[selectShipping]', event.target.value);
    };

    useEffect(() => {
        if (typeof localStorage.getItem('ByerFormShipping[selectShipping]') === 'undefined' || localStorage.getItem('ByerFormShipping[selectShipping]') == null) {
            let elemcl=document.getElementById('selectShipping1');
            elemcl.checked=true;
            localStorage.setItem('ByerFormShipping[selectShipping]', 'Προπληρωμή μεταφορικών');
        }else{
            if(localStorage.getItem('ByerFormShipping[selectShipping]') === 'Προπληρωμή μεταφορικών'){
                let elemcl=document.getElementById('selectShipping1');
                elemcl.checked=true;
            }else if(localStorage.getItem('ByerFormShipping[selectShipping]') === 'Πληρωμή Μεταφορικών στην Παράδοση'){
                let elemcl=document.getElementById('selectShipping2');
                elemcl.checked=true;
            }
        }
    }, []);

    if (typeof localStorage.getItem('ByerFormCount') !== 'undefined' && localStorage.getItem('ByerFormCount') !== null) {
        if (localStorage.getItem('ByerFormCount').includes('-')) {
            let allReadyKeys = localStorage.getItem('ByerFormCount').replaceAll('null', '0');
            myarr = allReadyKeys.split('-');
        }
    }

    if (typeof localStorage.getItem('ByerFormShipping[message]') !== undefined && localStorage.getItem('ByerFormShipping[message]') !== null) {
        orderComment=localStorage.getItem('ByerFormShipping[message]').replaceAll('"', '');
    }

    return (
        <Container className='px-1 px-xl-8'>
            <ContainerDivider className='pt-4 pb-3'>
                <Titles>
                    <h2>Καταχώριση Εντολής Αγοράς</h2>
                    <p>Σύνοψη Στοιχείων Εντολής Αγοράς</p>
                </Titles>
            </ContainerDivider>
            <div className='stepper-table'>
                <div className='d-none d-xl-block'>
                    <div className='table-grid'>

                        <div className='table-title bformc'>
                            <div>Α/Α</div>
                            <div>Κατάστημα</div>
                            <div>Προϊόν</div>
                            <div>Link Προϊόντος</div>
                            <div>ΤΜΧ</div>
                            <div>Αξία</div>
                            <div>Σχόλιο</div>
                        </div>

                        {(() => {
                            let container = [];
                            let z = 0;
                            let tprice='';
                            let titleMerchant='';
                            let productComment='';

                            Object.keys(myarr).map(function (p, index) {
                                z++;

                                if (typeof localStorage.getItem('form[' + p + '][product-price]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-price]') !== null) {
                                    tprice = localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '');
                                    totalprice += parseFloat(tprice) * parseInt(localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', ''));
                                }

                                if (typeof localStorage.getItem('form[' + p + '][product-shop]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-shop]') !== null) {
                                    if (localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '') === "-1") {
                                        titleMerchant = localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                    } else {
                                        titleMerchant = localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '');
                                    }

                                    if (typeof localStorage.getItem('form[' + p + '][product-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-comment]') !== null) {
                                        productComment = localStorage.getItem('form[' + p + '][product-comment]').replaceAll('"', '');
                                    }
                                }

                                container.push(
                                    <div className='table-body bformc' key={index}>
                                        <div className='item'>{z}</div>
                                        <div className='item'>{titleMerchant}</div>
                                        <div className='item'>{localStorage.getItem('form[' + p + '][product-name]').replaceAll('"', '')}</div>
                                        <div className='item'><a target='_blank' rel='noreferrer' href={localStorage.getItem('form[' + p + '][product-link]').replaceAll('"', '')}>Σύνδεσμος</a></div>
                                        <div className='item'>{localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', '')}</div>
                                        <div className='item'>{localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '')}€</div>
                                        <div className='item'>
                                            <CommentIcon data-tip={productComment} data-place='left' data-background-color='#2599FC' data-text-color='#fff' data-effect='solid' data-multiline={true} alt='information button' />
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                )

                            })
                            return container;
                        })()}
                    </div>
                </div>

                <div className='step-3-mobile-view d-block d-xl-none'>

                    {(() => {
                        let container = [];
                        let z = 0;
                        let titleMerchant='';
                        Object.keys(myarr).map(function (p, index) {
                            z++;

                            let productComment='';

                            if (typeof localStorage.getItem('form[' + p + '][product-shop]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-shop]') !== null) {
                                if (localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '') === "-1") {
                                    titleMerchant = localStorage.getItem('form[' + p + '][newTitle]').replaceAll('"', '');
                                } else {
                                    titleMerchant = localStorage.getItem('form[' + p + '][product-shop]').replaceAll('"', '');
                                }
                            }
                            if (typeof localStorage.getItem('form[' + p + '][product-comment]') !== 'undefined' && localStorage.getItem('form[' + p + '][product-comment]') !== null) {
                                productComment=localStorage.getItem('form[' + p + '][product-comment]').replaceAll('"', '');
                            }

                            container.push(
                                <div key={index}>
                                    <div className='product-title'>{z}. {localStorage.getItem('form[' + p + '][product-name]').replaceAll('"', '')}</div>
                                    <div className='card-body'>
                                        <div className='card-container'>
                                            <div className='card-title'>A/A</div>
                                            <div className='card-detail'>{z}</div>
                                        </div>
                                        <div className='card-container'>
                                            <div className='card-title'>Κατάστημα</div>
                                            <div className='card-detail'>{titleMerchant}</div>
                                        </div>
                                        <div className='card-container'>
                                            <div className='card-title'>Προϊόν</div>
                                            <div className='card-detail'>{localStorage.getItem('form[' + p + '][product-name]').replaceAll('"', '')}</div>
                                        </div>
                                        <div className='card-container'>
                                            <div className='card-title'>Link Προϊόντος</div>
                                            <div className='card-detail'><a target='_blank' rel='noreferrer' href={localStorage.getItem('form[' + p + '][product-link]').replaceAll('"', '')}>Σύνδεσμος</a></div>
                                        </div>

                                        <div className='card-container'>
                                            <div className='card-title'>TMX</div>
                                            <div className='card-detail'>{localStorage.getItem('form[' + p + '][product-quantity]').replaceAll('"', '')}</div>
                                        </div>
                                        <div className='card-container'>
                                            <div className='card-title'>Αξία</div>
                                            <div className='card-detail'>{localStorage.getItem('form[' + p + '][product-price]').replaceAll('"', '')}€</div>
                                        </div>

                                        <div className='card-container'>
                                            <div className='card-title'>Σχόλιο</div>
                                            <div className='card-detail'>{productComment}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                            return container;
                        })
                        return container;
                    })()}

                </div>

                <div className='table-summary total-value'>
                    <div className='table-summary-label'>Γενικό Σύνολο</div>
                    <div className='table-summary-value'>{totalprice.toFixed(2)} €</div>
                </div>

                <div className='table-payment-deitails-container'>
                    <div className='table-payment-details'>

                        {(() => {
                            if (typeof localStorage.getItem('ByerFormShipping[collection-stores]') !== 'undefined' && localStorage.getItem('ByerFormShipping[collection-stores]') !== null) {
                                return (
                                    <GetStoresDetails storeTitle={localStorage.getItem('ByerFormShipping[collection-stores]').replaceAll('"', '')} />
                                )
                            }else{
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Παραλαβής</div>

                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                {localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιοχή:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[email]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }
                        })()}

                        {(() => {
                            if (typeof localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== 'undefined' && localStorage.getItem('ByerFormShipping[receiptFirst-name]') !== null) {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                  {localStorage.getItem('ByerFormShipping[receiptFirst-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[receiptLast-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptAddress]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptArea]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Πόλη:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptTown]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptZip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptEmail]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[receiptTelephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }else if (typeof localStorage.getItem('ByerFormShipping[businessName]') !== 'undefined' && localStorage.getItem('ByerFormShipping[businessName]') !== null) {
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Επωνυμία:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessName]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Δραστηριότητα:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessProfession]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΑΦΜ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessAfm]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΔΟΥ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessDoy]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessAddress]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Πόλη:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessTown]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessZip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessEmail]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[businessTelephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }else{
                                return (
                                    <div className='table-payment-details-holder'>
                                        <div className='table-payment-details-title'>Στοιχεία Τιμολόγησης</div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Ονοματεπώνυμο:</span>
                                            <span className='blue-color-1'>
                                                {localStorage.getItem('ByerFormShipping[first-name]').replaceAll('"', '')} {localStorage.getItem('ByerFormShipping[last-name]').replaceAll('"', '')}
                                            </span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Διεύθυνση:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[address]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιφέρεια:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[area]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Περιοχή:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[town]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>ΤΚ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[zip]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>email:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[email]').replaceAll('"', '')}</span>
                                        </div>
                                        <div className='table-payment-details-elements'>
                                            <span className='grey-color-1'>Τηλ:</span>
                                            <span className='blue-color-1'>{localStorage.getItem('ByerFormShipping[telephone]').replaceAll('"', '')}</span>
                                        </div>
                                    </div>
                                )
                            }
                        })()}

                        <div className='table-payment-details-holder'>
                            <div className='table-payment-details-title' >Σχόλια Παραγγελίας</div>
                            <div className='table-payment-details-comments'>{orderComment}</div>
                        </div>

                    </div>
                </div>


                <div className='table-payment-deitails-container' style={{paddingTop:'50px', marginTop:'0px'}} >
                    <div className='row'>
                        <div className='col-sm-12 col-xl-3 table-payment-details-title' style={{fontSize: '1.125rem',color:'#285daa'}}>Πληρωμή Μεταφορικών:</div>
                        <div className='col-sm-12 col-xl-3 grey-color-1'>
                            <label htmlFor='selectShipping1'>Προπληρωμή μεταφορικών &nbsp;</label>
                            <input onChange={setOnChangeShipping} type='radio' id='selectShipping1' name='ByerFormShipping[selectShipping]' value='Προπληρωμή μεταφορικών' />
                            <div style={{fontSize: '12px'}}>Το κόστος των μεταφορικών θα αξιολογηθεί και θα συμπεριληφθεί στο link πληρωμής που θα λάβετε</div>
                        </div>

                        <div className='col-sm-12 col-xl-5 grey-color-1'>
                            <label htmlFor='selectShipping2'>Πληρωμή μεταφορικών στην παράδοση &nbsp;</label>
                            <input onChange={setOnChangeShipping} type='radio' id='selectShipping2' name='ByerFormShipping[selectShipping]' value='Πληρωμή Μεταφορικών στην Παράδοση' />
                            <div style={{fontSize: '12px'}}>Το κόστος μεταφορικών θα αξιολογηθεί, θα σας γνωστοποιηθεί, ΔΕΝ θα περιληφθεί στο link πληρωμής και θα εξοφληθεί κατά την παράδοση</div>
                        </div>
                    </div>
                </div>

            </div>
        </Container>
    );
};

function getStepContent(step) {
    switch (step) {
        case 0:
            return (<Step1 />);
        case 1:
            return (<Step2 />);
        case 2:
            return (<Step3 />);
        default:
            return 'unknown step';
    }
}

const LinaerStepper = event => {

    const classes = useStyles();
    const methods = useForm({});

    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();

    const handleNext = (data, event) => {
        window.scrollTo(0,0);
        setTimeout(() => {
            if (activeStep === steps.length - 1) {
                setActiveStep(activeStep + 1);
            } else {
                setActiveStep(activeStep + 1);
                setSkippedSteps(skippedSteps.filter(skipItem => skipItem !== activeStep));
            }
        }, 100);


    };

    const handleBack = () => {
        window.scrollTo(0,0);
        setTimeout(() => {
            setActiveStep(activeStep - 1);
        }, 100);
    };

    return (
        <Fragment>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                    const labelProps = {};
                    const stepProps = {};
                    return (
                        <Step {...stepProps} key={index}>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <h3 align='center'>
                    <ProcessOrder />
                </h3>
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleNext)}>
                        {getStepContent(activeStep)}
                        <ContainerDivider className='py-2'>
                            <div className='text-center'>
                                <Button className={classes.button} disabled={activeStep === 0} onClick={handleBack}>
                                    Προηγούμενο
                                </Button>
                                <Button className={classes.button} variant='contained' color='primary' type='submit'>
                                    {activeStep === steps.length - 1 ? 'Ολοκλήρωση' : 'Επόμενο'}
                                </Button>
                            </div>
                        </ContainerDivider>
                    </form>
                </FormProvider>
            )}
        </Fragment>
    );
};

export default LinaerStepper;