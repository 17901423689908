import classes from './Card.module.scss';

function Card(props) {
	return (
		<div className={classes['custom-card-container']} {...props}>
			{props.title || props.number > 0 ? (
				<div className={classes['custom-card-title']}>
					<span className='DtaTitle'>{props.number}</span> {props.title}
				</div>
			) : null}
			<div className={classes['custom-card-body']}>{props.children}</div>
		</div>
	);
}

export default Card;
