import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import Contact from '../Pages/Contact';
import CooperationRequest from '../Pages/CooperationRequest/CooperationRequest';
import Faq from '../Pages/Faq';
import PageStyles from '../UI/PageStyles/PageStyles';
import Container from '../UI/Container/Container';

function ViewPageContent() {

    const { navLink } = useParams();
    const [getLink, setGetLink] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios
            .get(
                `${API_URL}/api/content_management_system?${API_KEY}&filter[active]=[1]&filter[link_rewrite]=${navLink}&display=full&output_format=JSON`
            )
            .then(pages => {

                setTimeout(() => {
                    if (typeof pages.data.content_management_system !== 'undefined' && pages.data.content_management_system !== null) {
                        setGetLink(pages.data.content_management_system);
                        setIsLoading(true);
                    }else{
                        setIsError(true);
                    }
                }, 200);
            });
    }, [navLink]);


    if(isLoading) {
        return (
            <>
                {getLink.map((item, index) => {

                    if (item.id === 15) {
                        return (
                            <PageStyles key={index} className='p-2 p-xl-4'>
                                <Container className='px-0 px-xl-15'>
                                    <Contact/>
                                </Container>
                            </PageStyles>
                        );
                    } else if (item.id === 19) {
                        return (
                            <PageStyles key={index} className='p-2 p-xl-4'>
                                <Container className='px-0'>
                                    <CooperationRequest/>
                                </Container>
                            </PageStyles>
                        );
                    } else if (item.id === 12) {
                        return (
                            <PageStyles key={index} className='p-2 p-xl-4'>
                                <Container className='px-0 px-xl-15'>
                                    <Faq/>
                                </Container>
                            </PageStyles>
                        );
                    } else {
                        return (
                            <PageStyles key={index} className='p-2 p-xl-4'>
                                <Container className='px-0 px-xl-15'>
                                    {ReactHtmlParser(item.content[1].value)}
                                </Container>
                            </PageStyles>
                        );
                    }
                })}
            </>
        );
    }else if(isError){
        setTimeout(() => {
            window.location = 'http://gteshops.com';
        }, 2000);

        return (
            <div>
                <PageStyles className='p-2 p-xl-4'>
                    <Container className='px-0 px-xl-15'>
                        <div style={{textAlign:'center'}}>Το Gteshops άλλαξε και η σελίδα δεν υπάρχει πλέον. Θα μεταφερθείτε στην αρχική.</div>
                    </Container>
                </PageStyles>
            </div>
        );

    }else{

        return (
            <div style={{height:'2000px'}}>
                <PageStyles className='p-2 p-xl-4'>
                    <Container className='px-0 px-xl-15'>
                            Loading...
                    </Container>
                </PageStyles>
            </div>
        );
    }
}

export default ViewPageContent;
