import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Merchants from '../Merchants/Merchants';
import { withStyles } from '@material-ui/core/styles';
import Titles from '../../UI/Titles/Titles';
import MerchantsCategories from '../Merchants/MerchantCategories';
import { withRouter } from 'react-router-dom';
import classes from '../Merchants/Merchants.module.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AntTabs = withStyles({
    root: { borderBottom: '1px solid #e8e8e8' },
    indicator: { backgroundColor: '#285DAA' },
})(Tabs);

const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        marginRight: theme.spacing(4),
        fontFamily: 'CF Asty Std Medium',
        fontSize: '18px',
        color: '#8191A0',
        letterSpacing: '0px',
    },

    selected: { color: '#285DAA' },
}))(props => <Tab disableRipple {...props} />);

function MerchantTabs(props) {

    const query = new URLSearchParams(props.location.search);
    const mcatId = query.get('catId');

    let activeTab = 0;
    if (mcatId > 0) {
        activeTab = 1;
    }

    const [value, setValue] = React.useState(activeTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Fragment>
            <Titles className='title-style-1 text-center mt-10 mt-xl-0'>
                <h2> Καταστήματα </h2>
            </Titles>

            <AntTabs value={value} onChange={handleChange} aria-label='simple tabs example' centered>
                <AntTab className={classes.tabText} label='Αλφαβητικά Εύρεση' {...a11yProps(0)} />
                <AntTab className={classes.tabText} label='Εύρεση ανά κατηγορία' {...a11yProps(1)} />
            </AntTabs>

            <TabPanel value={value} index={0}>
                <Merchants />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <MerchantsCategories />
            </TabPanel>
        </Fragment>
    );
}

//export default MerchantTabs;
export default withRouter(MerchantTabs);
