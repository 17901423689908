import { Fragment } from 'react';
import classes from './PageStyles.module.scss';

function PageStyles(props) {
	return (
		<div className={`${classes['page-content']} ${props.className}`}>
			<Fragment>{props.children}</Fragment>
		</div>
	);
}

export default PageStyles;
