import PageStyles from '../UI/PageStyles/PageStyles';
import LinearStepper from '../components/LinearStepper';

function Buy() {
    return (
        <PageStyles className='pb-3 mt-12 mt-lg-0'>
            <LinearStepper />
        </PageStyles>
    );
}

export default Buy;
