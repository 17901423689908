import React, {useState } from "react";
import './MultiSelect.scss';
import AccordionItem from "./AccordionItem";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import classes from "../Input/Input.module.scss";
import Input from "../Input/Input";
import CustomInput from "../CustomInput/CustomInput";

class MultiSelect extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isOpenMulti : false,
            tags: [],
            extra_cat : ''
        }

    }

    componentDidUpdate(){
        //console.log(this.state.tags);
    }

    checkchange(cat_id, e){

        if( e.target.checked === true ){
            this.setState(prevState => ({
                tags: [...prevState.tags, cat_id]
            }))
        }else{
            var array = [...this.state.tags];
            var index = array.indexOf(cat_id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({tags: array});
            }
        }
    }
    changeExtraCat(name){
        this.setState({
            extra_cat: name
        });
    }
    clearButton(e) {
        e.preventDefault();
        this.setState({tags: []});
    }
    addButton(e){
        e.preventDefault();
        this.setState({isOpenMulti: false});
    }

    deleteTag(cat_id){
        var array = [...this.state.tags];
        var index = array.indexOf(cat_id)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({tags: array});
        }
    }

    OpenCloseMulti(){
        this.setState((prevState, props) => ({
            isOpenMulti: !prevState.isOpenMulti
        }));
    }

    render(){
        return(

            <div className='category-selection'>
                <div className='category-selection__button' onClick={ () => this.OpenCloseMulti() }>
                    <span className='category-selection__button-label'>Επιλέξτε κατηγορία/ίες καταστήματος</span>
                    <span className='category-selection__button-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6.1" height="10.671" viewBox="0 0 6.1 10.671">
                        <path d="M5.875.224A.762.762,0,0,0,4.8.224L.224,4.8a.762.762,0,0,0,0,1.079L4.8,10.447A.763.763,0,1,0,5.875,9.368L1.841,5.335,5.876,1.3a.762.762,0,0,0,0-1.079Z" transform="translate(6.1 10.671) rotate(180)" fill="#fff"/>
                    </svg>
                </span>
                </div>

                <div className='category-selection__results'>

                    {this.state.tags.length ? (
                        <div className='category-selection__results-label'>Επιλογές</div>
                    ) : (
                        <></>
                    )}

                    <div className='category-selection__results-content result-items'>
                        {this.props.options.map((category, index) => {
                            if(  this.state.tags.indexOf(category.cat_id) > -1  ){
                                return (
                                    <div key={index} className='result-item'>
                                        <span className='result-item__label'>{category.cat_name}</span>
                                        <span className='result-item__close' onClick={ () => this.deleteTag(category.cat_id) }><svg xmlns="http://www.w3.org/2000/svg" width="10.608" height="10.608" viewBox="0 0 10.608 10.608"><path d="M11.743,12.8,7.5,8.561,8.561,7.5,12.8,11.743,17.047,7.5l1.061,1.061L13.864,12.8l4.243,4.243-1.061,1.061L12.8,13.865,8.56,18.108,7.5,17.047Z" transform="translate(-7.5 -7.5)" fill="#85a3ce"/></svg></span>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    { this.state.extra_cat && <div className='category-selection__results-label'>Άλλη κατηγορία : {this.state.extra_cat}</div>}


                    <div className={`multi-box ${this.state.isOpenMulti ? 'opened' : 'closed'}`}>
                        <div className='multi-box__header'>
                            <a href='#' onClick={ (e) => this.clearButton(e) }>καθαρισμός επιλογών <span>({this.state.tags.length})</span></a>
                        </div>
                        {this.props.options.map((category, index) => {
                            if( category.parent_id === 0 ){

                                if( !this.props.nochildren.includes(category.cat_id) ){

                                    return (

                                        <AccordionItem key={index}  data={this.props.options} title={category.cat_name} catID={category.cat_id} >
                                            {this.props.options.map((subCategory, index2) => {
                                                if( subCategory.parent_id === category.cat_id ){
                                                    return (

                                                        <div key={index2} className='multi-box__item multi-box__item--nochild'>
                                                            <label className='multi-box__head'>
                                                                <div className='multi-box__label'  >
                                                                    {subCategory.cat_name}
                                                                </div>
                                                                <div className='multi-box__checkbox'>
                                                                    <CustomCheckbox name='categories' value={subCategory.cat_id} forceValue={  this.state.tags.indexOf(subCategory.cat_id) > -1 ? true : false } onChange={ (e) => this.checkchange(subCategory.cat_id, e) } />
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </AccordionItem>

                                    )
                                }else{
                                    return (
                                        <div className='multi-box__item multi-box__item--nochild' key={index}>
                                            <label className='multi-box__head'>
                                                <div className='multi-box__label'  >
                                                    {category.cat_name}
                                                </div>
                                                <div className='multi-box__checkbox'>
                                                    <CustomCheckbox name='categories' value={category.cat_id} forceValue={  this.state.tags.indexOf(category.cat_id) > -1 ? true : false } onChange={ (e) => this.checkchange(category.cat_id, e) } />
                                                </div>
                                            </label>
                                        </div>
                                    )
                                }

                            }
                            return null;
                        })}
                        <div className='multi-box__extra-cat'>
                            <CustomInput name='extra-category' value='' label='Άλλη κατηγορία' type='text' placeholder='Εισάγετε άλλη κατηγορία' onChange={ (e) => this.changeExtraCat(e) } required={false}/>
                        </div>

                        <div className='multi-box__footer'>
                            <div className='multi-box__actions'>
                                <button className='btn multi-box__button multi-box__button--add' onClick={ (e) => this.addButton(e) }>Προσθήκη</button>
                            </div>
                        </div>

                    </div>



                </div>
            </div>

        )
    }

}

export default MultiSelect;