import React from "react";


class CustomCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            checked: props.forceValue,
        })
    }

    handleCheckboxChange = event =>{
        this.setState({ checked: event.target.checked });
        this.props.onChange(event);
    }


    render() {
        return (
            <>
                <span className="c-custom-checkbox">
                    <input type="checkbox" name={this.props.name} value={this.props.value} checked={this.state.checked} onChange={ this.handleCheckboxChange}/>
                    <span className="c-custom-checkbox__icon c-custom-checkbox__empty"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g fill="#fff" stroke="#394f65" strokeWidth="1.5"> <rect width="20" height="20" rx="2" stroke="none"/> <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="1.25" fill="none"/> </g></svg></span>
                    <span className="c-custom-checkbox__icon c-custom-checkbox__checked"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"> <g transform="translate(-1139 -1777)"> <rect width="20" height="20" rx="2" transform="translate(1139 1777)" fill="#285daa"/> <path d="M10705.076,1312.61l3.488,3.1,5.661-7.22" transform="translate(-9560.65 473.966)" fill="none" stroke="#fff" strokeWidth="2"/> </g></svg></span>
                </span>

            </>
        )
    }
}

export default CustomCheckbox;