import React from 'react';
import Input from '../UI/Input/Input';
import classes from './ShowNewForm.module.scss';

class ShowNewForm extends React.Component {

	render() {
		return (
			<div className={classes['show-new-form']}>
				<Input label='Όνομα Kαταστήματος' type='text' placeholder='Εισάγετε το όνομα καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newTitle]`)} name={`form[${this.props.number}][newTitle]`} required={true} />
				<Input label='Διεύθυνση Καταστήματος' type='text' placeholder='Εισάγετε την διεύθυνση καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newAddress]`)} name={`form[${this.props.number}][newAddress]`} required={true} />
				<div className='row'>
					<div className='col-sm-12 col-xl-3'>
						<Input label='TK' type='text' placeholder='Εισάγετε τον ταχυδρομικό κώδικα καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newPc]`)} name={`form[${this.props.number}][newPc]`} required={true} />
					</div>
					<div className='col-sm-12 col-xl-9'>
						<Input label='Πόλη - Περιοχή' type='text' placeholder='Εισάγετε την πόλη & την περιοχή καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newTown]`)} name={`form[${this.props.number}][newTown]`} required={true} />
					</div>
				</div>
				<Input label='Τηλέφωνο' type='text' placeholder='Εισάγετε το τηλέφωνο καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newTelephone]`)} name={`form[${this.props.number}][newTelephone]`} required={true} />
				<Input label='Email' type='email' placeholder='Εισάγετε το email καταστήματος' value={localStorage.getItem(`form[${this.props.number}][newEmail]`)} name={`form[${this.props.number}][newEmail]`} required={true} />
			</div>
		);
	}
}

export default ShowNewForm;
