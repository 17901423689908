import classes from './StoreCard.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Telephone } from '../../assets/images/svg/telephone.svg';
import { ReactComponent as Email } from '../../assets/images/svg/mail.svg';
import { ReactComponent as Pin } from '../../assets/images/svg/pin.svg';

function StoreCard({ items }) {
    return (
        <div className={classes['store-container']} key={items.id}>
            <div className={classes['store-image-container']}>
                <img className='img-fluid' src={items.image} alt='list icon' />
            </div>
            <Link to={{ pathname: items.url }} target='_blank' className={`${classes['store-link']} ${classes['trancute']}`}>
                {items.name}
            </Link>

            <ul className={classes['store-information-list']}>
                <li>
                    <Telephone />
                    {items.telephone}
                </li>
                <li>
                    <Email />
                    {items.email}
                </li>
                <li>
                    <Pin />
                    {items.address}, {items.area}, {items.city}
                </li>
            </ul>
        </div>
    );
}

export default StoreCard;
