import classes from './PoweredBy.module.scss';
import { Link } from 'react-router-dom';
import Container from '../../UI/Container/Container';
import afternetLogo from '../../assets/images/afternet-logo.jpg';

function PoweredBy() {
    return (
        <div className={classes['powered-by']}>
            <Container className='text-center'>
                Powered By{' '}
                <Link to={{ pathname: 'https://www.afternet.gr/' }} target='_blank'>
                    <img src={afternetLogo} alt='Afternt Internet Services' style={{ margin: '0 7px' }} />
                    Afternet
                </Link>
            </Container>
        </div>
    );
}

export default PoweredBy;
