import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios/index';
import ReactTooltip from 'react-tooltip';
import tooltipicon from '../assets/images/svg/tooltip-icon.png';
import searchIcon from '../assets/images/svg/search-icon.png';
import { Link } from 'react-router-dom';

export default function AutoCompleteInput() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const API_KEY = `${process.env.REACT_APP_KEY}`;
        const API_URL = `${process.env.REACT_APP_URL}`;
        axios
            .get(''+ API_URL +'/api/merchantscategories?'+ API_KEY +'&filter[active]=[1]&display=full&output_format=JSON')
            .then(category => {
                setCategories(category.data.gteshops_merchants_categories_entitys);
            })

            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <Autocomplete
            sx={{
                width: 300,
                root: {
                    outline: 'none',
                    padding: 0,
                },
                '& input': {
                    padding: '0!important',

                    '& label': {
                        color: 'black',
                        position: 'relative',
                        transform: 'translate(0)',
                        display: 'none',
                    },
                    '& fieldset': {
                        notchedOutline: {
                            borderColor: 'transparent',
                        },
                        borderColor: 'transparent',
                        padding: '0 15px',
                        '&:hover': {
                            borderColor: 'transparent!important',
                            border: 'none',
                        },
                        '& legend': {
                            display: 'none',
                            '& hover': {
                                border: 'none',
                            },
                        },
                    },
                },
                '.MuiIconButton-root': {
                    padding: 0,
                    display: 'none',
                },
            }}
            id='custom-input'
            // open={true}
            options={categories}
            disablePortal={true}
            noOptionsText={'Δεν βρέθηκαν αποτελέσματα'}
            getOptionLabel={option => option.name}
            renderOption={(props, option) => (
                <li key={option.id}>
                    <Link to={`merchants?catId=${option.id}`}>{option.name}</Link>
                </li>
            )}
            renderInput={params => (
                <div className='custom-input-styles'>
                    <img style={{ width: '18px', height: '18px' }} src={searchIcon} alt='search icon' />
                    <TextField {...params} placeholder='Αναζήτηση' type='search' />
                    <img style={{ width: '28px', heigth: '28px', cursor: 'pointer' }} src={tooltipicon} data-tip='Αναζητήστε eShops και καταστήματα βάσει κλάδου δραστηριοποίησης' data-place='right' data-background-color='#2599FC' data-text-color='#fff' data-effect='solid' data-multiline={true} alt='information button' />
                    <ReactTooltip />
                </div>
            )}
        />
    );
}
