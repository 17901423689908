import ItemsList from '../../UI/ItemsList/ItemsList';
import Container from '../../UI/Container/Container';
import PoweredBy from '../PoweredBy/PoweredBy';
import Footer from '../../UI/Footer/Footer';
import classes from '../../UI/ItemsList/ItemsList.module.scss';
import { MenuCategories } from '../../context/MenuCategories';

function FooterContainer(props) {
    return (
        <Footer>
            <MenuCategories>
                <div className='horizontal-divider'>
                    <Container>
                        <ItemsList
                            className={classes['horizontal-list']}
                            cmsCategory={'4'}
                        />
                    </Container>
                </div>

                <ItemsList
                    className={classes['disclaimer-menu']}
                    cmsCategory={'5'}
                />
            </MenuCategories>

            <PoweredBy />
        </Footer>
    );
}

export default FooterContainer;
