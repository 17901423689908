import React, { useState } from 'react';
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

const AccordionItem = (props) => {
    const [isActive, setIsActive] = useState(false);


    return (
        <>
            <div className='multi-box__item multi-box__item--haschild'>
                <div className='multi-box__head' onClick={() => setIsActive(!isActive)}>
                    <div className='multi-box__label'>{props.title}</div>
                    <div className='multi-box__drop'>
                        <svg xmlns="http://www.w3.org/2000/svg"  width="12" height="16" viewBox="0 0 384 512"><path fill='currentColor' d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>
                    </div>
                </div>

                <div className={`multi-box__body ${isActive ? 'opened' : 'closed'}`} >

                    {props.children}

                </div>
            </div>
        </>

    );
};

export default AccordionItem;