import React, { Component } from 'react';
import Container from '../../UI/Container/Container';
import axios from 'axios';
import StoreCard from './StoreCard';

class GetListingMerchants extends Component {
    constructor(props) {
        super(props);
        this.state = { merch: [] };
    }

    componentDidMount() {
        this.getMerc();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.catId !== this.props.catId) {
            this.getMerc();
        }
    }

    getMerc() {

        if (typeof this.props.catId === 'number') {
            
            let url='';
            const API_KEY = `${process.env.REACT_APP_KEY}`;
            const API_URL = `${process.env.REACT_APP_URL}`;

            if(this.props.catId === 0){
                url=''+ API_URL +'/api/merchants?'+ API_KEY +'&display=full&output_format=JSON&sort=[name_ASC]&filter[active]=[1]';
            }else{
                url=''+ API_URL +'/api/merchants?'+ API_KEY +'&filter[active]=[1]&filter[categories]=%["' + this.props.catId + '"]%&display=full&output_format=JSON&sort=[name_ASC]';
            }
            axios.get(url).then(pages => {
                if (typeof pages.data.gteshops_merchants_entitys === 'undefined') {
                    this.setState({ merch: [] });
                } else {
                   
                    let data_res = pages.data.gteshops_merchants_entitys;
                    
                    let ebloko_stores = [
                        {
                            active: "1",
                            address: "Λεωφόρος Θηβών 274",
                            area: "Αιγάλεω",
                            categories: "[\"2\",\"3\",\"4\",\"5\",\"7\",\"8\",\"9\",\"10\",\"11\",\"12\"]",
                            city: "Αττική",
                            email: "info@ebloko.gr",
                            id: 238,
                            id_country: "",
                            id_state: "",
                            image: "https://app.gteshops.com/img/mer/eblokogr1-61b8c7ab46fac.jpeg",
                            name: "ebloko.gr",
                            telephone: "-",
                            url: "https://ebloko.gr/"
                        },
                        {
                            active: "1",
                            address: "Κηφισού 14",
                            area: "Άγιος Ιωάννης Ρέντης",
                            categories: "[\"6\"]",
                            city: "Αττική",
                            email: "info@eblokomarket.gr",
                            id: 14,
                            id_country: "9",
                            id_state: "358",
                            image: "https://app.gteshops.com/img/mer/eblokomarketgr-61b8c876c70aa.jpeg",
                            name: "eblokomarket.gr",
                            telephone: "210-0102500",
                            url: "https://eblokomarket.gr/" 
                        }
                    ];
                    
//                    let ebloko_stores = data_res.filter(function (el) {
//                        return el.id === 14 || el.id === 238;
//                    });
                    let rest_stores = data_res.filter(function (el) {
                        return el.id !== 14 || el.id !== 238;
                    });
                    const total_stores = [...ebloko_stores, ...rest_stores];

                    this.setState({
                        merch: total_stores,
                    });
                }
            });
        } else {
            
            let flag='';

            if(this.props.catId === 'num'){
                flag='';
            }else{
                flag='&filter[name]=['+this.props.catId+']%';
            }
            const API_KEY = `${process.env.REACT_APP_KEY}`;
            const API_URL = `${process.env.REACT_APP_URL}`;
            axios.get(''+ API_URL +'/api/merchants?'+ API_KEY +'&filter[active]=[1]'+flag+'&display=full&sort=[name_ASC]&output_format=JSON').then(pages => {
                if (typeof pages.data.gteshops_merchants_entitys === 'undefined') {
                    this.setState({ merch: [] });
                } else {

                    let data_res = pages.data.gteshops_merchants_entitys;

//                    let ebloko_stores = data_res.filter(function (el) {
//                        return el.id === 14 || el.id === 238;
//                    });
                    
                    let ebloko_stores = [
                        {
                            active: "1",
                            address: "Λεωφόρος Θηβών 274",
                            area: "Αιγάλεω",
                            categories: "[\"2\",\"3\",\"4\",\"5\",\"7\",\"8\",\"9\",\"10\",\"11\",\"12\"]",
                            city: "Αττική",
                            email: "info@ebloko.gr",
                            id: 238,
                            id_country: "",
                            id_state: "",
                            image: "https://app.gteshops.com/img/mer/eblokogr1-61b8c7ab46fac.jpeg",
                            name: "ebloko.gr",
                            telephone: "-",
                            url: "https://ebloko.gr/"
                        },
                        {
                            active: "1",
                            address: "Κηφισού 14",
                            area: "Άγιος Ιωάννης Ρέντης",
                            categories: "[\"6\"]",
                            city: "Αττική",
                            email: "info@eblokomarket.gr",
                            id: 14,
                            id_country: "9",
                            id_state: "358",
                            image: "https://app.gteshops.com/img/mer/eblokomarketgr-61b8c876c70aa.jpeg",
                            name: "eblokomarket.gr",
                            telephone: "210-0102500",
                            url: "https://eblokomarket.gr/" 
                        }
                    ];
                    let rest_stores = data_res.filter(function (el) {
                        return el.id !== 14 || el.id !== 238;
                    });

                    const total_stores = [...ebloko_stores, ...rest_stores];
                    this.setState({
                        merch: total_stores,
                    });
                    
                }
            });
        }
    }

    render() {
        return (
            <Container>
                <div className='row'>
                    {this.state.merch.length === 0 ? (
                        <div className='col-12 text-center'>
                            <h5>Δεν βρέθηκαν καταστήματα</h5>
                        </div>
                    ) : (
                        this.state.merch.map((item, index) => {
                            if (this.props.catId === 'num') {
                                if(item.name.charAt(0) <= '9' && item.name.charAt(0) >= '0') {
                                    return (
                                        <div key={index} className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                                            <StoreCard items={item}/>
                                        </div>
                                    );
                                }
                            } else {
                                return (
                                    <div key={index} className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                                        <StoreCard items={item}/>
                                    </div>
                                );
                            }
                            return null;
                        })
                    )}
                </div>
            </Container>
        );
    }
}

export default GetListingMerchants;
